
@import "../../../../sass/imports";

.loading-splash-screen-modal {

}

.loading-splash-wings {
    &:after, &:before {
        position: absolute;
        content: ' ';
        display: block;
        box-shadow: 0 -8px 21px 0 rgba(0, 0, 0, 0.2);
    }

    &:before {
        background-color: $raspberry;
        left: 50%;
    }

    &:after {
        background-color: $text-gray;
            right: 50%;
    }


    .leaf {
        position: absolute;
        left: 50%;
        @include transform(translate(-50%, 0));
        color: $mint-green3;
        text-shadow: -8px 21px 0 rgba(0, 0, 0, 0.2);
    }


    @include orientation-portrait() {
        &:after, &:before {
            width: 2000px;
            height: 2000px;
            bottom: calc(10% + 190px);
            @include border-radius(2000px);
        }

        &:before {
            @include transform(translate(-90%, 90%));
        }

        &:after {
            @include transform(translate(90%, 90%));
        }

        .leaf {
            bottom: calc(10% + 10px);
        }
    }

    @include orientation-landscape() {
        &:after, &:before {
            width: 1500px;
            height: 1500px;
            bottom: 50%;
            @include border-radius(1500px);
        }

        &:before {
            @include transform(translate(-85%, 100%));
        }

        &:after {
            @include transform(translate(85%, 100%));
        }

        .leaf {
            bottom: calc(50% - 205px);
        }
    }
}

.loading-splash-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(250, 250, 250, 1);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0; bottom: 0;
    left: 0; right: 0;
    overflow: hidden;

    opacity: 0;
    @include transition(opacity .25s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.loading-splash-screen-container {
    color: white;
    font-size: 50px;
    z-index: 10;

    margin: 0;
    padding: 60px 20px;
    text-align: center;
    opacity: 0.95;

    > header {
        max-width: 250px;
        margin: 1em auto 0 auto;

        > img {
            width: 200px;
            border-bottom: 1.8px solid $raspberry;
            padding: 0 0 19px 0;
        }
    }

    h2 {
        color: $text-navy;
        font-size: 22px;
        font-weight: normal;
    }

    h3 {
        color: $raspberry;
        font-size: 22px;
    }

    .spinner {
        margin: 40px auto;
        padding: 10px 0;

        > i {
            color: $text-gray;
            display: inline-block;
            font-size: 76px;
        }
    }

    > p {
        color: $text-gray;
        font-size: 17.1px;
        margin-top: 20px;
    }

}
