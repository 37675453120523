
@import "../../../sass/imports";

.hp-conditions-scroller {
    /* Mobile */
    @include max-screen(47em) {
        max-width: 940px;
        margin: 20px auto;
        background-color: white;

        .header {
            vertical-align: top;
            padding: 0 0 20px 0;

            h2 {
                font-size: 18px;
                font-weight: 900;
                text-align: center;
                color: $text-navy;
            }
        }

        .hp-scroll-left, .hp-scroll-right {
            display: none;
        }

        .scroller-main {
            max-width: none;
            display: inline-block;
            width: 100%;
        }

        .card-container {
            max-width: 150px !important;
            min-width: 150px !important;
            width: 150px !important;
            font-size: 12px;
            padding: 0 0 10px 0 !important;
            color: $text-gray !important;

            .card-inner-container {
                @include box-shadow(0, 20px, 20px, 0, rgba(0,0,0,0.1));
                margin: 4px;
                position: relative;
                padding: 15px 15px 65px 15px !important;
            }

            i {
                font-size: 52px;
                display: block;
                margin: auto;
                color: $text-gray !important;
            }

            span {
                text-transform: uppercase;
                position: absolute;
                bottom: 20px;
                left: 0;
                width: 100%;
                text-align: center;
                display: inline-block;
                padding: 0 15px;
                color: $text-gray !important;
            }
        }

    }

    /* Desktop */
    @include min-screen(47em) {
        @include box-shadow(0, 20px, 20px, 0, rgba(0,0,0,0.1));
        max-width: 940px;
        margin: 20px auto;
        background-color: white;
        position: relative;
        top: -70px;

        .header {
            display: inline-block;
            width: 23%;
            vertical-align: top;
            padding: 60px 40px;

            h2 {
                font-size: 18px;
                font-weight: 900;
                text-align: left;
                color: $text-navy;
            }
        }

        .hp-scroll-left, .hp-scroll-right {
            position: absolute;
            top: 0; bottom: 0;
            border: none;
            background: none;
            padding: 0 20px;
            color: $text-navy;
        }

        .hp-scroll-left {
            left: 18%;
        }

        .hp-scroll-right {
            right: 0px;
        }

        .scroller-main {
            max-width: none;
            display: inline-block;
            width: calc(80% - 80px);
        }

        .card-container {
            max-width: 110px !important;
            min-width: 110px !important;
            width: 110px !important;
            padding: 0 !important;
            cursor: pointer;

            .card-inner-container {
                position: relative;
                padding-bottom: 55px !important;
                padding-top: 16px !important;
                margin: 5px;
                width: 102px;
            }

            i {
                font-size: 52px;
                display: block;
                margin: auto;
                color: $text-gray !important;
            }

            span {
                color: $text-gray !important;
                font-size: 12px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}
