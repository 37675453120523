
@import "../../../sass/imports";

.meal-feed-grid {
    width: 100%;
    margin: 0px auto;
    padding: 130px 0 0 0;

    @supports (padding-top: constant(safe-area-inset-top)) {
        padding-top: calc(130px + constant(safe-area-inset-top));
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(130px + env(safe-area-inset-top));
    }

    .grid-container {
        max-width: 1070px;
        margin: auto;
        position: relative;

        @include min-screen(47em) {
            max-width: calc(100vw - 60px);
            margin: auto 60px auto 0;
        }
    }

    .meal-types-column {
        width: 40px;
        margin-top: 48px;
        margin-left: 0px;
        display: inline-block;
        vertical-align: top;
    }

    .prev-page-btn, .next-page-btn {
        position: absolute;
        z-index: 5;
        top: 7px;
        border: none;
        background: none;
        font-size: 26px;
        color: $bluey-gray;

        @include max-screen(47em) {
            display: none;
        }
    }

    .prev-page-btn {
        left: 0;
    }

    .next-page-btn {
        right: 0;
    }

    .grid-today-btn {
        margin: 15px auto;
        display: block;

        &[data-invisible="true"] {
            visibility: hidden;
        }
    }

    .grid-prev-week-btn, .grid-next-week-btn {
        font-size: 30px;
        padding: 258px 25px;
        margin: 50px 30px 0 0;
        border: none;
        background: transparent;
        color: $bluey-gray;
    }

    .meal-types-row {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #eef1f4;
        text-align: left;
        margin-bottom: 18px;
        height: 127px;
        @include border-radius(0px 4px 4px 0px);

        @include min-screen(47em) {
            border: 1px solid #7c8a99;
            @include border-radius(4px);
        }

        .meal-details {
            @include transition(all 0.3s ease-in-out);
            @include transform(rotate(90deg) translate(-50%, -50%));
            position: absolute;
            width: 127px;
            text-align: center;
            line-height: 1;
            left: 50%;
            top: 50%;
            transform-origin: 0 0;
            color: $text-gray;
            font-weight: bold;
            text-transform: uppercase;

            > span {
                display: block;
                font-size: 10px;
            }

            .range {
                font-weight: normal;
                margin-top: 3px;
            }
        }
    }

    .meals-viewport-outer {
        width: 100%;
        display: inline-block;
    }

    .meals-column-viewport {
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0px, 0px, 0px);
        -webkit-transform: translate3d(0px, 0px, 0px);
        position: relative;
        width: 100%;
    }

    .meals-column-container {
        padding-left: 14px;
    }

    .meals-column-cell {
        display: table-cell;
        vertical-align: top;
        padding: 10px 0 0 10px;

        &[data-present="true"] {
            // use background-image in case we adjust positioning
            background-image: linear-gradient(rgba(44,210,150,.15), rgba(44,210,150,.15));
            border-radius: 4px;
        }

    }

    // adjust to make meal types column sticky or non-sticky
    .meal-types {
        position: sticky;
        left: 0;
        right: 0;
        z-index: 1000;
    }

    .day-offset-header {
        border: none;
        @include border-radius(4px);
        margin-bottom: 18px;
        margin-right: 8px;
        padding: 7px 57px 7px 7px;
        text-align: center;
        width: 205px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: white;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
        color: $text-navy;
        border: 1px solid transparent;
        cursor: pointer;
        overflow: visible;
        position: relative;
        z-index: 20;
        padding: 0 0 0 7px;

        display: flex;
        align-items: center;

        &.past {
            padding: 0 0 0 32px;
        }

        @include max-screen(47em) {
            &.present, &.future {
                border: 1px solid #d1d5e7;
            }
        }

        border: 1px solid #d1d5e7;

        &.past {
            border: 1px solid #7c8a99;
            &:after {
                content: 'LOG';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 33px;
                background-color: #7c8a99;

                color: white;
                font-size: 8.3px;
                font-weight: bold;
                padding: 10px 0;
                text-align: center;
            }
        }

        &.past {
            .daily-stats-btn, .show-more-btn {
                border-left: 1px solid #7c8a99;
            }
        }

        &.present {
            color: white;
            background-color: $mint-green3;
            border: 1px solid $mint-green3;
            .daily-stats-btn {
                border-left: 1px solid $mint-green3;
            }
        }

        .date {
            flex: 10 1;
        }
    }

    .daily-stats-btn, .show-more-btn, .no-button {
        border: none;
        background: white;
        border-left: 1px solid #d1d5e7;
        padding: 8px;
        color: $text-navy;
        height: 28px;
        flex: 1;
    }

    .no-button {
        border-left: none;
    }

    .show-more-btn {
        .dropdown-btn {
            @include transform(rotate(90deg));
            border: none;
            background: none;
        }

        .popup-content {
            display: flex;
            flex-direction: column;
        }

        .el-medium-btn {
            margin: 10px;
        }
    }

    .meal-cell-outer-container {
        // border: 1px solid #e7e7e7;
        // @include border-radius(4px);
        margin-right: 10px;
        margin-bottom: 18px;
        position: relative;
        height: 127px;
    }
}
