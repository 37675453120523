

@import "../../../sass/imports";
@import "../../pro/components/Modals/Modals.scss";

.provisional-date-range {
    color: $text-gray;
    display: inline-block;
    padding: 3px 14px;
    cursor: pointer;

    em {
        white-space: nowrap;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: $raspberry;
    }

    i {
        font-size: 21px;
    }
}

.choose-date-range-container {
    .el-labeled-input {
        margin: 0 0 20px 0;

        label {
            text-transform: uppercase;
            display: block;
            text-align: center;
        }
    }

    .date-selector-popover {
        .dropdown-btn {
            @include border-radius(4px);
            @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));
            border: solid 0.5px rgba(0, 0, 0, 0.05);
            background-color: white;
            padding: 10px 20px;
            text-align: center;
            font-size: 15px;

            color: $text-navy;
            width: 200px;
            margin: auto;
            display: block;

            i {
                color: $raspberry;
                font-size: 22px;
                margin: 0 20px 0 0;
                vertical-align: middle;
            }
        }

        .dropdown-dropdown {
            @include transform(translate(-50%, 0px) !important);
        }

        .dropdown-content {
            padding: 0 !important;
            border-radius: 10px;
            min-width: 280px;
        }

        .prev-date-btn, .next-date-btn {
            display: none;
        }
    }
}

