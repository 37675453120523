@import "../../../../sass/imports";

.category-hero-module {
    margin: 1em;
    max-width: 680px;
    overflow: hidden;
    @include border-radius(5px);
    @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));

    @include min-screen(713px) {
        margin: 1em auto;
    }

    a, a:visited {
        color: $text-navy;
        cursor: pointer;
    }

    img {
        width: 100%;
    }

    .module-text {
        padding: 20px;
    }

    h2 {
        margin: 0 0 1em;
        font-size: 22px;
    }

    p {
        font-size: 16px;
    }

    .tags {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: $bluey-gray;
        margin: 10px 10px 0px 10px;
        min-height: 1em;
    }

    footer {
        text-align: right;

        button {
            @include flat-button($orange4);
        }
    }
}
