@import "../../../sass/imports";

.home-page-header {
    padding: 20px;
    background-color: $dark-slate-gray;
    position: relative;
    z-index: 10;

    .logo-container {
        display: inline-block;
        color: white !important;
        font-size: 12px;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        img {
            height: 22px;

            @include min-screen(47em) {
                height: 37px;
            }
        }

        em {
            color: white !important;
            vertical-align: bottom;
            display: inline-block;
            margin: 0 0 0 5px;

            @include min-screen(47em) {
                font-size: 18px;
            }
        }
    }

    .tagline {
        color: white;
        font-size: 10px;
        font-weight: 500;

        @include min-screen(47em) {
            font-size: 1em;
        }
    }

    .menu-container {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
        padding: 0 12px 0 0;

        @include min-screen(47em) {
            padding: 0 29px 0 0;
        }

        button {
            border: none;
            background: none;
            padding: 10px 10px;
            font-size: 10px;
            text-transform: uppercase;
            margin-left: 10px;
            font-weight: bold;
            color: white;

            @include min-screen(47em) {
                padding: 23px 10px;
            }
        }

        .for-pros-btn {
            border: none;
            background: none;
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 0.83px;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid white;
            }

            @include min-screen(47em) {
                font-size: 14px;
                line-height: 0.86;
                letter-spacing: 1.17px;
            }
        }

        .sign-in-btn {
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.83px;
            padding: 10px 10px;

            @include min-screen(47em) {
                @include outline-button(white);
                background-color: transparent;
                padding: 10px 19px;
                font-size: 10px;
                letter-spacing: 0.83px;
                margin: 0 10px;

                &:hover {
                    color: $text-gray;
                }
            }
        }

        .sign-up-btn {
            @include min-screen(47em) {
                @include flat-button($orange4);
                padding: 10px 19px;
                font-size: 10px;
                letter-spacing: 0.83px;
                margin: 0 10px;
            }
        }

        .flat-orange-btn {
            @include flat-button($orange4);
            padding: 10px 19px;
            font-size: 10px;
            letter-spacing: 0.83px;
            margin: 0 10px;
        }


        > .sign-in-btn,
        > .sign-up-btn {
            display: none !important;

            @include min-screen(47em) {
                display: inline-block !important;
            }
        }

        .dropdown-container {
            @include min-screen(47em) {
                display: none;
            }

            .dropdown-btn:after, .close-btn {
                display: none;
            }

            .dropdown-btn {
                font-size: 16px;
                margin-left: 0px;
                padding: 7px 20px;
            }

            .dropdown-content {
                min-width: 200px;
                background-color: white;
            }

            .dropdown-dropdown {
                @include transform(translate(-85%, -10px));
            }

            .triangle, .triangle2 {
                left: 85%;
            }

            .sign-in-btn, .sign-up-btn {
                width: 150px;
                color: $text-gray;
                border: 1px solid $inactive-text-gray;

                &:hover {
                    background-color: $mint-green3;
                    color: white;
                }
            }

            .sign-up-btn {
                margin-bottom: 10px;
            }
        }
    }

    .mobile-submenu {
        @include min-screen(47em) {
            display: none;
        }

        .dropdown-btn:after, .close-btn {
            display: none;
        }

        .dropdown-btn {
            font-size: 16px;
            margin-left: 0px;
            padding: 7px 20px;
        }

        .dropdown-content {
            min-width: 200px;
            background-color: white;

            > footer {
                white-space: nowrap;
                text-align: right;
                padding: 20px 0 0 0;
                border-top: 1px solid $light-gray-border;
                margin: 20px 0 0 0;
            }
        }

        .dropdown-dropdown {
            @include transform(translate(-91%, -10px));
        }

        .triangle, .triangle2 {
            left: 91%;
        }

        .outline-btn {
            @include outline-button($text-gray);
            background-color: transparent;
            padding: 10px 19px;
            font-size: 10px;
            letter-spacing: 0.83px;
            margin: 0 10px 0 0;
        }

        .flat-btn {
            @include flat-button($orange4);
            padding: 10px 19px;
            font-size: 10px;
            letter-spacing: 0.83px;
            margin: 0 0 0 10px;
        }
    }
}
