@import "../../sass/imports";

.non-homepage-login {
    padding-top: 120px;
}

.home-page {
    .header-row {
        text-align: center;
    }

    .assistive-text {
        clip: rect(1px,1px,1px,1px);
        position: absolute!important;
    }

    .inner-container {
        display: block;
        margin: 0 auto;
        max-width: 1000px;
        width: 92%;
    }

    .banner-featured-on {
        background-color: white;
        padding: 40px 0;

        .la-times {
            max-width: 260px;
        }

        .lagx {
            max-width: 70px;
        }
    }

    .banner-grocery {
        background-color: white;
        padding: 40px 0;

        .grocery-logo-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin: 0 auto;

            .wholefoods-logo {
                height: 100px;
                width: auto;
                min-width: 120px;

                @include max-screen(47em) {
                    height: 70px;
                }
            }

            img {
                @include min-screen(47em) {
                    width: 210px;
                }
            }

        }

    }

    .container-featured {
        clear: both;
        padding: 1.5em 0;
        text-align: center;

        @include min-screen(47em) {
            max-width: 900px;
            margin: 0 auto;
        }

        img {
            display: inline-block;
            justify-content: space-between;
            margin: 1em 2em;
            width: 115px;

            &[data-long="true"] {
                margin: 0 2em;
            }

            &.amazon-fresh {
                width: 225px;
            }
        }

        h3 {
            font-size: 22px;
            font-weight: 900;
            line-height: 1.27;
            color: $text-navy;
            max-width: 65%;
            margin: 0px auto 25px;

            @include min-screen(47em) {
                max-width: 90%;
                font-size: 36px;
                line-height: 1.4;
            }
        }

        .sports-academy {
            width: 200px;
        }

        .keck  {
            width: 150px;
        }

        .ftsf {
            width: 80px;
        }

        .cooper {
            width: 275px;
        }

        .american {
            width: 175px;

        }

        .fordham {
            width: 275px;
        
        }

        .mayo {
            width: 100px;
        
        }        

        .panasonic {
            width: 215px;
        
        }

        .ca-wellness {
            width: 100px;
        
        }

        .minnesota { 
            width: 135px; 
        }
    }

    .banner-conditions {
        background-color: white;
        padding-top: 20px;

        @include min-screen(47em) {
            background-color: #f5f7fa;
            padding-top: 0;
            margin: 0 0 -125px 0;
            position: relative;
            top: -70px;
        }
    }

    .banner-consultation {
        background-image: url(https://static.chewba.info/images/hp-08-05/banner-consultation.jpg);
        background-size: cover;
        color: white;
        -moz-background-size: cover;
        -o-background-size: cover;
        padding: 3em 0;
        -webkit-background-size: cover;
        width: 100%;
        text-align: center;

        @include min-screen(47em) {
            padding: 123px 0 93px 0;
        }

        h2 {
            font-size: 22px;
            font-weight: 900;
            line-height: 1.27;

            @include min-screen(47em) {
                font-size: 40px;
                line-height: normal;
                margin: 0 0 34px 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.38;

            @include min-screen(47em) {
                font-size: 24px;
                line-height: 1.33;
                margin: 0 0 44px 0;
            }
        }

        .content-consultation {
            .btn-container {
                margin-top: 3em;
                a:before{
                    @include icon-font;
                    content: $icon-messages3;
                    font-size: 16px;
                    margin-right: 16px;
                }
            }

            @include min-screen(47em) {
                margin: 0 auto;
                width: 65%;
            }
        }

        .request-consult-btn {
            @include flat-button($orange4);
            font-size: 12px;
            font-weight: bold;
            line-height: 1.17;
            letter-spacing: 1.2px;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(255, 119, 53, 0.2);
            padding: 13px 44px;

            @include min-screen(47em) {
                font-size: 16px;
                font-weight: bold;
                line-height: 0.88;
                letter-spacing: 1.6px;
                padding: 21px 80px;
            }
        }
    }

    .banner-accredited {
        text-align: center;
        padding: 40px 0;
        background-color: white;

        @media (min-width:47em) {
            margin: 0 auto;
            width: 65%;
        }

        > img, > section {
            display: inline-block;
            vertical-align: top;
        }

        h3 {
            margin-top: 40px;

            @include min-screen(47em) {
                text-align: left;
                margin-top: 30px;
            }
        }

        > img {
            max-width: 213px;

            @include min-screen(47em) {
                margin-right: 2em;
            }
        }

        > section {
            max-width: 390px;
        }

        p {
            font-size: 20px;
            line-height: 1.6;
            text-align: center;
            color: $yet-another-gray2;
            padding: 0 20px;

            @include min-screen(47em) {
                text-align: left;
                padding: 0;
            }
        }
    }
}

.professional-modal {
    .login-modal-container > .login-form {
        max-width: 700px;
    }

    .login-form .form-container {
        max-width: 700px;
    }
}

.pro-modal-container {
    color: $text-navy;
    text-align: center;

    .dietetics, .fitness, .medical {
        display: inline-block;

        max-width: 250px;
        margin: 20px 40px;
    }

    .medical {
        max-width: 350px;
    }

    .close-modal-btn {
        border: none;
        background: none;
        color: $bluey-gray;
        font-size: 18px;

        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        padding: 10px;
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
    }

    h3 {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0.75em 0;
    }

    p {
        font-size: 14px;
    }

    .learn-more-btn {
        @include flat-button($orange4);
    }

    > header {
        padding: 20px 0;
        position: relative;
    }

    > section footer {
        padding: 15px;
    }
}
