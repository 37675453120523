
@import "./../../../../sass/imports";

.notifications-dropdown-container  {

    &.el-dropdown-popup {

        position: absolute;
        .badge {
            padding: 3px 5px !important;
        }

        .close-btn {
            .icon-close-x {
                color: $el-grayish-blue;
                font-weight: bold;        
            }
        }

        .dropdown-dropdown {
            @include transform(translate(-108%, 20px));
            width: 223px;
            overflow-y: hidden;

            >.dropdown-container {

                >.dropdown-content {
                    border-radius: 10px;
                    padding: 20px 17px 10px;

                }

                .triangle, .triangle2 {
                    left: 92.5%;
                }

                ul {
                    padding: 10px 0;
                    max-height: 53vh;
                    overflow-y: auto;
                    li {
                        margin: 0;
                        padding: 0;
                        position: relative;

                        button {
                            margin: 0;
                            padding: 0;

                            > span {
                                color: $el-grayish-blue;
                                font-size: 16px;
                                display: inline-block;
                            }

                            .badge {
                                display: inline-block;

                                position: absolute;
                                font-size: 10px;
                                top: 2px;
                                left: 11px;
                                color: #fff;
                                background-color: $orange4;
                                @include border-radius(10em);
                                width: 16px;
                                height: 16px;
                                display: inline-block;
                                font-weight: bold;
                                padding: 2.25px 0;
                                cursor: pointer;
                                white-space: nowrap;
                                text-align: center;
                            }
                           
                            i {
                                color: $raspberry;
                                vertical-align: middle;
                                padding-right: 15px;
                                font-size: 20px;
                                line-height: 35px;
                            }

                        }
                    }
                }


                h5 {
                    color: $el-grayish-blue4;
                    font-size: 18px;
                    text-align: center;
                    font-weight: bold;
                }


                .message-center-btn {
                    margin: 15px auto;
                    display: block;
                }
            }
        }
    }


}
