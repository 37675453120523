
@import "../../../../sass/imports";

.add-missing-meals {
    position: absolute;
    z-index: 20;
    top: 0;
    left: calc(50% + 150px);

    > button, .dropdown-btn {
        background-color: white;
        border: none;
        @include border-radius(60px);
        width: 30px;
        height: 30px;
        color: $bluey-gray;
        font-size: 14px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(168, 172, 190, 0.2);
    }

    .dropdown-dropdown {
        top: auto !important;
        left: 0% !important;
        @include transform(translate(calc(-100% - 0px), -30px) !important);
    }

    .dropdown-content {
        margin-top: 0 !important;
        margin-right: 10px !important;

        width: 200px;

        p {
            color: $text-navy;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
        }

        ul {
            margin-top: 10px;
        }
    }

    .triangle, .triangle2 {
        white-space: nowrap;
        top: 10px !important;
        left: auto !important;
        transform: rotate(90deg) !important;
        right: -4px;
    }

    .triangle2 {
        right: -3px !important;
    }

    .add-missing-meal-btn {
        width: calc(100% - 10px);
        border: 1px solid $light-gray-border;
        padding: 8px;
        margin: 5px;
        background: white;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $text-gray;
        @include border-radius(5px);
        @include transition(all .25s ease-in-out);

        &:hover {
            color: white;
            background-color: $text-gray;
        }
    }

}
