@import "../../../../sass/imports";

.article-list-module {
    margin: 1em;

    .global-search-results {
        .result-container {
            @include min-screen(47em) {

            }
        }
    }

    .global-search-results-params {
        display: none;
    }

    .doc-type-params {
        margin: 10px 0;
        text-align: center;
        li {
            display: inline-block;
        }
    }


}

.landing-keyword-search {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.custom-filter-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
