
@import "./../../../../sass/imports";

.header-dropdown-container {
    position: relative;
    display: inline-block;
    vertical-align: top;

    .badge {
        display: inline-block;

        position: absolute;
        top: 15px;
        right: 4px;
        color: #fff;
        background-color: $orange4;
        @include border-radius(10em);
        width: 16px;
        height: 16px;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        padding: 2.25px 0;
        cursor: pointer;
        white-space: nowrap;
        text-align: center;
    }
}

.header-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: top;

    .dropdown-btn {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:after {
            display: none;
        }
    }

    .dropdown-dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        // @include transform(translate(-80%, -20px));
        width: 300px;
        max-width: calc(100vw - 50px);
        max-height: 0;

        overflow: hidden;
        z-index: 100;
        width: 350px;

        opacity: 0;

        .dropdown-container {
            @include transition(all .5s cubic-bezier(.09,1.07,.32,1.11));
            position: relative;
            width: 100%;
            @include transform(translate(0%, -100%));
        }

        .triangle {
            position: absolute;
            top: 0;
            // left: 80%;
            // opacity: 0;
            @include transform(translateX(-50%));
            // @include transition(opacity 1.25s);
            z-index: 27;

            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #e0e0e0;
        }

        .triangle2 {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #FFF;

            position: absolute;
            top: 1px;
            // left: calc(80% + 0px);
            // opacity: 0;
            @include transform(translateX(-50%));
            // @include transition(opacity 1.25s);
            z-index: 27;
        }

        .dropdown-content {
            width: 100%;
            margin: 10px 0 15px 0 !important;
            background-color: #ffffff;
            @include box-shadow(0, 8px, 8px, 0, rgba(0, 0, 0, 0.2));

            border: solid 0.5px #e0e0e0;
            z-index: 26;
        }

    }

    &[data-state="true"] {
        .dropdown-dropdown {
            opacity: 1;
            overflow: visible;
            display: block;
            max-height: 30em;
        }

        .dropdown-container {
            @include transform(translate(0%, 0%));
        }
    }

    &[data-closing="true"] {
        .dropdown-container {
            opacity: 0;
        }
    }
}
