@import "../../../sass/imports";

.chunk-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.80);
    backdrop-filter: sepia(1) brightness(0.75) blur(5px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 20px;

    display: none;

    &[data-visible="true"] {
        display: flex;
    }

    h1, h2, h3, p {
        color: $el-white;
    }

    .icon-spinner2 {
        display: block;
        margin: 80px 0;
        font-size: 80px;
        color: $el-grayish-blue3;
    }
}
