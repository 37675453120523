@import "../../../sass/imports";

.export-modal {
    .nutrition-export-form {
        display: flex;
        flex-direction: column;

        @include min-screen(650px) {
            flex-direction: row;
        }

        .custom-calendar {
            flex: 7;
        }

        .export-toggle-btns {
            flex: 2;

            h6 {
                margin: 0 0 20px 0;
                white-space: nowrap;
            }
        }
    }

    .export-select {
        display: flex;
        flex-direction: column;

        @include min-screen(450px) {
            flex-direction: row;
        }

        margin-bottom: 10px;

        h6 {
            flex: 2;
            line-height: 36px;
        }

        > div {
            flex: 5;

            .export-dropdown-wrapper {
                width: 260px;
            }
        }
    }

    .nutrition-export-complete {
        text-align: center;
        position: relative;
        width: 300px;
        min-height: 200px;
        margin: auto;

        .feather-mail,
        .feather-download,
        .icon-spinner {
            mask-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 1)
            );
            -webkit-mask-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 1)
            );

            font-size: 90px;
        }

        .icon-spinner {
            display: block;
            color: $raspberry;
        }

        .feather-check {
            margin: 0;
            position: absolute;
            z-index: 1;
            top: 70px;
            right: 100px;
        }

        p {
            margin: 40px 0 0 0;
        }
    }

    .select-date-tip-left {
        @include max-screen(649px) {
            display: none;
        }
    }

    .select-date-tip-footer {
        text-align: center;
        @include min-screen(650px) {
            display: none;
        }
    }

    footer {
        display: flex;
        flex-direction: column;
        padding: 10px;


        @include max-screen(390px) {
            .footer-actions {
                display: flex;
                flex-direction: column-reverse;
                grid-gap: 10px;
                width: -webkit-fill-available;
            }
        }

        .error-msg {
            min-height: 26px;
            font-size: 12px;
            line-height: 26px;
            text-align: center;
            margin: 8px 0;
            color: $el-error;
        }
    }

}
