
@import "../../../sass/imports";

.admin-dashboard {
    width: calc(100% - 120px);
    max-width: 940px;
    min-height: calc(100vh - 60px);
    margin: 60px auto 0 auto;
    padding: 60px 0 0 0;

    @include max-screen(960px) {
        margin: 110px 20px 0 20px;
        width: calc(100% - 40px);
    }

    h1 {
        color: white;
        font-size: 22px;
        padding: 20px 0 10px 0;
        text-align: center;
        z-index: 20;
        position: fixed;
        top: 30px;
        left: 50%;
        @include transform(translate(-50%, 0));
    }

    .tool-buttons {
        max-width: 600px;
        margin: auto;
        text-align: center;
        padding: 0;

        button {
            height: 10em;
            width: 10em;
            vertical-align: top;
            margin: 1em;
            padding: 10px;
            color: white;
            @include border-radius(5px);
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            border: none;

            i {
                font-size: 45px;
                margin: 0 0 10px 0;
                display: inline-block;
            }
        }
    }

    .foods-db-btn {
        background-color: $kelly-green;
    }

    .category-db-btn {
        background-color: $dark-magenta;
    }

    .newsletters-btn {
        background-color: $orange4;
    }

    .nutrition-patterns-btn {
        background-image: $el-raspberry-gradient1;
    }

    .brands-db-btn {
        background-color: $text-gray;
    }

    .accounts-btn {
        background-color: $red5;
    }

    .pages-btn {
        background-color: $blue4;
    }

    .test-page-btn {
        background-image: $el-blue-gradient;
    }
}
