@import "../../../sass/imports";

.feed-goals {
    width: 100%;
    min-height: 190px;
    margin: 0px auto 0px auto;
    padding: 17px 0 10px 0;
    // background-color: white;
    // box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.03), inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:after {
        display: block;
        content: ' ';
        position: absolute;
        top: 7px;
        left: 50%;
        @include transform(translate(-50%, 0%));
        @include border-radius(50%);
        z-index: -1;

        // background-color: $hip-gray;
        box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.14);
        background-image: linear-gradient(180deg, #5b7287 0, #324253 45%, #324253);
        width: 134vw;
        height: 80vw;

        @include min-screen(700px) {
            width: 109vw;
            height: 400px;
        }
    }

    > header {
        margin: 0 30px;

        @include min-screen(47em) {
            margin: 10px 30px 0 30px;
        }
    }

    .goal-ctrls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px auto 0 auto;

        h2 {
            font-size: 12px;
            color: $el-white;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    > footer {
        padding: 20px;
        max-width: 520px;
        width: 100%;
        vertical-align: top;
        display: inline-block;
        @include min-screen(540px) {
            padding: 15px 0;
        }
    }

    .prev-week-btn, .next-week-btn {
        position: relative;
        background: none;
        border: none;

        font-size: 14px;
        line-height: 1.27;
        font-weight: bold;

        padding: 15px 10px;
        vertical-align: middle;
        color: $el-white;
    }


    .daily-log-btn {
        display: block;
        margin: auto;
        font-size: 14px;
        font-weight: normal;

        &.el-btn-badge-right {

            &.el-medium-btn {
                padding: 10px 40px 10px 20px;

                @include min-screen(47em) {
                    padding: 6px 40px 6px 20px;
                }
            }

            .el-btn-badge {
                right: 12px;

                @include min-screen(47em) {
                    height: 22px;
                    width: 22px;
                    right: 6px;
                    font-size: 14px;
                }
            }
        }

        &.el-grayish-blue-outline-btn {
            &:hover {
                background-color: $el-gray3;
                color: $el-grayish-blue;
            }
        }
    }
}

.goals-summary-list {
    text-align: center;
    display: block;
    width: 100%;
    max-width: 370px;
    margin: 0px auto;

    li {
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: calc(33% - 0px);
        position: relative;

        margin: 0px;

        .amount {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 36px);
        }

        .value {
            font-size: 13px;
            color: white;
            margin: 0 0 5px 0;
        }

        .numerator {
            font-size: 21px;
            font-weight: bold;
            white-space: nowrap;
            line-height: 1;
        }

        p {
            font-size: 11px;
            color: $bluey-gray;
            max-width: 75px;
            margin: auto;
            line-height: 1.1;
        }

        &:after {
            content: ' ';
            height: 38px;
            width: 1px;
            position: absolute;
            top: 13px;
            right: 0;
            border-right: 1px solid $bluey-gray;
            opacity: .5;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    .small-progress-bar {
        display: block;
        width: 53px;
        height: 4px;
        @include border-radius(8px);

        background-color: #818995;
        margin: 2px auto 5px auto;
    }

    .small-progress-bar-fill {
        height: 4px;
        @include border-radius(8px);
        background-image: linear-gradient(to right, #bcbcbc, #fbfafa);

        &.high {
            @include background-image(linear-gradient(to left, #b40078, #fa607d));
        }
    }
}
