
@import "../../../sass/imports";

.daily-log-meal {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    min-height: 0;
    margin-left: 20px;

    &:after {
        content: ' ';
        display: block;
        border-bottom: 1px solid #d8d8d8;
        margin: 20px 0 0 0;
    }

    @include transition(all 0.333s ease-in-out);

    &[data-log-portions="true"], &[data-deleted="true"] {
        background-image: linear-gradient(to left, #f1ddb6, rgba(237, 214, 162, 0.29));
        min-height: 83px;
        border-bottom: none;

        &:after {
            display: none;
        }

        .log-portions, .undeleter {
            display: block;
        }

        .row-text {
            width: calc(100% - 140px);
        }
    }

    &[data-deleted="true"] {
        .row-text {
            text-decoration: line-through;
            font-style: italic;
            opacity: 0.75;
        }
    }

    .row-text {
        color: $text-navy;
        font-size: 13px;
        font-weight: bold;
        width: calc(100% - 100px);

        span {
            cursor: pointer;
        }

        .subtitle {
            margin-left: 5px;
            font-weight: 300;
        }

        .calories {
            font-weight: 300;
        }
    }

    > footer {
        display: flex;

        p {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }
    }

    .log-portions, .undeleter, > footer {
        > p {
            color: $text-navy;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 7px 0;
            text-align: center;
        }
    }

    .yes-btn, .no-btn, .save-btn {
        color: white;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 8px 20px;
        border: none;
        @include border-radius(40px);

    }

    .yes-btn, .save-btn {
        background-color: $raspberry;
        margin: 0 14px 0 0;
    }

    .no-btn {
        background-color: $bluey-gray;
    }

    .show-nutrition-btn, .meal-info-btn, .remove-log-btn {
        vertical-align: top;
        background: none;
        border: none;
        color: $josh-blue-gray;
        font-size: 11px;
        padding: 0 5px;
        width: 40px;
        height: 50px;
        margin-top: -15px;

        i {
            border: 1px solid $josh-blue-gray;
            @include border-radius(20px);
            padding: 5px;
            width: 22px;
            height: 22px;
            display: inline-block;
        }
    }

    .food-units-selector-outer {
        margin: 0;

        .open-modal-btn {
            text-align: left;
        }
    }

    .el-popup-left-bottom {
        .dropdown-btn {
            border: none;
            background: none;
        }
    }

    .food-warning {
        color: #bb130f;
        margin-left: 5px;
    }

    .unit-not-found {
        width: 200px;
        padding: 0 10px;

        > button {
            margin: 20px;
        }
    }
}

