
@import "./../../../../sass/imports";

//-- main header parent - default state
.header-parent {
    width: 100%;
    // position: fixed;
    // top: 0; left: 0; right: 0;
    // z-index: 10; // header goes over top of everything except modals
    @include transition(opacity 0.2s ease-in-out);

    &[data-clear-mode="true"] {
        z-index: -1;
        opacity: 0;

        .top-half-circle {
            display: none;
        }
    }

    @media print {
        .top-half-circle {
            display: none;
        }

        .header-container .return-to-dashboard a {
            color: $text-gray;
            text-decoration: none;
        }
    }

    .top-half-circle {
        position: fixed;
        z-index: 3;
    }

    .header-container {
        position: fixed;
        top: 0;

        left: 0;
        right: 0;
        z-index: 11;
    }

    &.flat-page-header {
        > header {
            border-radius: 0;
            transform: translate(-50%);
            height: 64px;
            width: 100%;
            @include background-image(linear-gradient(180deg, #596A79 0%, #3C4C5C 100%));
            z-index: 6;        

            @supports (padding-top: env(safe-area-inset-top)) {
                &:before {
                    content: ' ';
                    width: 100vw;
                    height: env(safe-area-inset-top);
                    bottom: 100%;
                    display: block;
                    position: absolute;
                    background-color: #596A79;
                }
            }
        }

        .header-container {
            max-width: 1500px;
        }

    }
}

.top-half-circle {
    position: absolute;
    top: 0;
    left: 50%;
    @include transform(translate(-50%,calc(-100% + 110px)));
    @include border-radius(50%);
    z-index: 0;

    @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
    @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

    &.no-curve {
        border-radius: 0;
    }

    width: 145vw;
    height: 370px;

    @include min-screen(700px) {
        width: 110vw;
        height: 200px;
        @include transform(translate(-50%,calc(-100% + 115px)));
    }
}

.header-container {
    position: relative;
    z-index: 1;
    height: 60px;
    max-width: 980px;
    margin: auto;

    .header-btns {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 0 12px 0 0;
        @include transform(translateY(-50%));

        @include max-screen(490px) {
            .for-dietitians {
                display: none;
            }
        }

        @include max-screen(752px) {

            > a, button {
                padding: 0px 7px;
                font-size: 10px;
                margin: 0 5px;

                &:first-child {
                    padding: 2px 7px;
                }

                &:last-child {
                    padding: 3px 7px;
                }     
            }
        }
    }

    .logged-in-header-btns {
        .notifications-btn, .dropdown-btn, .drawer-menu-btn {
            background-color: transparent;
            border: none;
            color: white;
            text-align: left;
            font-size: 1.25em;
            line-height: 1;
            padding: 5px 12px;

            i {
                font-size: 1em;
                line-height: 50px;
            }
        }
    }

    .on-secondary-page {
        display: none;
        @include min-screen(47em) {
            display: block;
        }
    }

    .version-notifier {
        position: absolute;
        top: 10px;
        left: 50%;
        @include transform(translate(-50%, 0));
        z-index: 10;
        padding: 17px;
        text-align: center;
        color: $text-gray;
        font-size: 14px;
        width: 100%;
        background: none;

        .el-alert {
            padding: 20px;
            margin-top: 30px;
            &[data-has-icon="true"] {
                padding: 20px 20px 20px 68px;
            }    
        }

    }

    .logo {
        position: absolute;
        top: 50%;
        left: 0;
        padding: 0 0 0 20px;
        @include transform(translateY(-50%));

        &.with-dietitian {
            display: none;

            @include min-screen(500px) {
                display: block;
            }
        }

        a {
            display: inline-block;
            background-image: url('https://static.chewba.info/images/eatlove-logo-white-2019.svg');
            background-repeat: no-repeat;
            background-size: cover;
            height: 23px;
            width: 100px;
            text-align:center;
        }
    }

    .return-to-dashboard {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        left: 0;
        width: 100%;
        text-align: center;

        a {
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
        }

        i {
            font-size: 26px;
            vertical-align: middle;
            display: inline-block;
            margin: 0 20px 0 0;
            color: $bluey-gray;

            position: absolute;
            top: 50%;
            left: 20px;
            @include transform(translateY(-50%));

            &:before {
                font-size: 18px;
                vertical-align: top;
                line-height: 26px;
            }

            @media print {
                display: none;
            }

            @include min-screen(47em) {
                border: 1px solid white;
                @include border-radius(20px);
                margin: 0 40px 0 0;
                padding: 0 0 0 5px;
                color: white;

                &:after {
                    content: 'BACK';
                    font-size: 12px;
                    font-family: Lato;
                    color: white;
                    padding: 7px 9px 0 7px;
                    vertical-align: top;
                    display: inline-block;
                }
            }
        }

        @media (max-width: 47em) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            a {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .env-indicator {
        color: white;
        font-weight: 900;
        position: absolute;
        top: 20px;
        right: 155px;
        text-transform: uppercase;
        color: $orange4;

        @include min-screen(500px) {
            left: 130px;
            right: auto;
        }
    }

    .my-dietitian-info {
        position: absolute;
        top: 50%;
        left: 0;
        max-height: 60px;
        max-width: calc(100% - 144px);
        overflow: hidden;
        padding: 0 20px 0 20px;
        margin: 0 25px 0 0;
        @include transform(translateY(-50%));
        line-height: 35px;

        @include min-screen(500px) {
            left: auto;
            right: 125px;
            max-width: none;
        }

        .avatar {
            width: 35px;
            height: 35px;
            @include border-radius(35px);
            @include box-shadow(0, 8px, 10px, 0, rgba(0, 0, 0, 0.15));
            vertical-align: top;
            float: left;
            margin: 0 5px 0 0px;
            display: inline-block;
        }

        em.avatar {
            font-size: 14px;
            white-space: nowrap;
            font-weight: bold;
            padding: 0px 4px;
            text-align: center;
            color: $raspberry;
            background-color: white;
        }

        i.avatar {

        }

        .avatar-logo {
            margin: 0;
            height: 35px;
        }

        .avatar-logo-text {

            font-size: 17px;
            color: #fff;
            font-weight: bold;
            opacity: .5;
            line-height: 35px;

            line-height: 1;
            margin: 0;

            @include min-screen(47em) {
                font-size: 20px;
                max-width: none;
            }
        }

        i.avatar {
            font-size: 22px;
            background-color: white;
            color: $raspberry;
            padding: 6px;
        }

        p {
            margin: 0 10px 0 5px;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            color: white;
            margin-right: 50px;
            display: block;
            white-space: nowrap;

            em {
                font-weight: bold;
            }
        }

        .icon-prescription-rx, .icon-pencil-notebook {
            color: white;
            top: 50%; right: 0;
            position: absolute;
            transform: translate(0, -50%);
            display: none;
            font-size: 20px;
            @include min-screen(500px) {
                display: inline-block;
            }
        }
    }

    .for-dietitians, .signin-btn {
        @include outline-button($text-gray);
        @include border-radius(0);
        border-width: .5px;
        font-size: 10px;
        padding: 9px 10px 9px 13px;
        margin-left: 10px;

        i {
            display: inline-block;
            padding-left: 8px;
        }
    }

    .signup-btn {
        @include flat-button($raspberry);
        @include border-radius(0);
        border-width: .5px;
        font-size: 10px;
        text-decoration: none;
        padding: 9px 30px;
        margin-left: 10px;
        @include transition(all 0.3s ease-in-out);
        &:hover {
            background-color: darken($raspberry, 10%);
            border-color: darken($raspberry, 10%);
        }
    }
}
