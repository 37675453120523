
@import "../../../../sass/imports";

.logging-summary-overlay {
    padding: 0;
    background: none;
    border: none;
    white-space: nowrap;

    &.safe-mode {
        i {
            display: inline-block;
            color: $mint-green3;
            font-size: 28px;
            text-align: center;
            margin: .5em auto;
            border: 3px solid $mint-green3;
            @include border-radius(56px);
            width: 56px;
            height: 56px;
            padding: 11px 0;
            vertical-align: middle;
        }

        p {
            text-align: center;
            font-size: 14px;
            font-weight: 900;
            line-height: 0.79;
            letter-spacing: 0.3px;
            text-align: center;
            color: white;
            display: inline-block;
            margin: 0 0 0 10px;
        }
    }

    .calories {
        text-align: center;
        display: inline-block;
        vertical-align: top;
        position: relative;

        margin: 0px;
        padding: 0 12px 0 0;

        .amount {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 36px);
        }

        .value {
            font-size: 12px;
            color: white;
        }

        .numerator {
            font-size: 20px;
            font-weight: bold;
            white-space: nowrap;
        }

        p {
            font-size: 10px;
            color: white;
            max-width: 85px;
            margin: auto;
            line-height: 1.1;
        }

        &:after {
            content: ' ';
            height: 38px;
            width: 1px;
            position: absolute;
            top: 13px;
            right: 0;
            border-right: 1px solid $bluey-gray;
            opacity: .5;
        }
    }

    .small-progress-bar {
        display: block;
        width: 53px;
        height: 4px;
        @include border-radius(8px);

        background-color: #818995;
        margin: 2px auto 5px auto;
    }

    .small-progress-bar-fill {
        height: 4px;
        @include border-radius(8px);
        background-image: linear-gradient(to right, #bcbcbc, #fbfafa);
    }

    .macros-legend {
        color: $light-blue-grey;
        font-size: 12px;
        padding: 0 0 0 14px;
        font-weight: bold;
        display: inline-block;
        text-align: left;

        > li {
            white-space: nowrap;
        }

        .color-key {
            font-size: 9px;
            padding: 3px;
            width: 20px;
        }

        .cho i {
            color: $raspberry;
        }

        .pro i {
            color: $bluey-gray;
        }

        .fat i {
            color: $mint-green3;
        }

        li > span {
            width: 35px;
            display: inline-block;
        }

        .name {
            font-size: 14px;
            color: white;
            width: 65px;
        }
    }

}
