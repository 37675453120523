
@import "./ConfirmOverwriteModal.scss";

.confirm-shift-overwrite-container {

    &.leftover-offsets-container {
        max-width: 400px;
    }

    .confirm-overwrite-form {
        padding: 20px 40px 0 40px;
    }

    .checkbox-btn {
        display: block;
    }
}
