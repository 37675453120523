
@import "../../sass/imports";

// Shared Styles
.account-settings-heading {
    font-size: 18px;
    color: $text-gray;
    text-transform: uppercase;
    margin: 5px;
}

// Layout Rules
.my-account {
    min-height: 100vh;
    background-color: white;
    background-color: $super-light-gray;
    padding: 120px 0 0 0;

    @supports (padding-top: constant(safe-area-inset-top)) {
        padding-top: calc(120px + constant(safe-area-inset-top));
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(120px + env(safe-area-inset-top));
    }
}

.my-account-login {
    min-height: 100vh;
    background-color: white;
    background-color: $super-light-gray;
    padding: 120px 0 0 0;

    &[data-hide-toolbar="true"] {
        padding: 0;
    }
}

.preferences-expansion {
    max-height: 0;
    overflow: hidden;;
    opacity: 0;
    background-color: white;
    @include transition(.25s all ease-in-out);

    > .inner-container {
        padding: 20px 10px;
        width: 100%;
    }

    &[data-status="true"] {
        max-height: 200em;
        opacity: 1;
        overflow: visible;
        border-bottom: 1px solid $bluey-gray;

        &:last-child {
            border-bottom: none;
        }
    }
}

// Account Tabs
.preferences-tabs {
    width: calc(100% - 40px);
    max-width: 540px;
    margin: 0px 20px 10px 20px;
    background-color: rgba(255, 255, 255, 0.9);
    // border: solid 0.5px rgba(0, 0, 0, 0.05);

    position: relative;
    top: auto; left: auto;
    @include transform(translateX(0%));

    @include border-radius(4px);
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));

    a {
        text-decoration: none;
    }

    @include min-screen(560px) {
        margin: 0px auto 20px auto;
    }

    .account-tab {
        display: block;
        border: 0;
        padding: 1em;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $bluey-gray;
        background-color: transparent;

        font-size: 16px;
        line-height: 1.2;
        display: block;

        font-weight: bold;
        padding: 15px;
        text-transform: uppercase;
        color: $text-navy;

        &.last-button {
            border-bottom: none;
        }

        > .inner-container {
            position: relative;
            width: 100%;

            &:after {
                @include icon-font;
                content: $icon-chevron-down;
                line-height: 2;
                color: $text-navy;
                font-size: 12px;
                padding: 5px;
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                @include transition(.33s all ease-in-out);
            }

            // div {
            //     vertical-align: top;
            //     width: 100%;
            // }
        }
        i {
            display: inline-block;
            vertical-align: top;
            font-size: 2em;
            color: $grayscale8;
            margin-right: 5px;
            @include transition(.15s all ease-in-out);
        }
    }

    .active-tab {
        font-weight: bold;

        > .inner-container:after {
            content: $icon-chevron-up;
        }
    }

}
