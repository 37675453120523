@import "../../../sass/imports";

.homepage-hero {
    padding: 30px;
    position: relative;
    text-align: center;
    overflow: hidden;
    color: #324253;
    position: relative;
    z-index: 0;
    // background-image: $custom-card-gradient;
    // background-image: linear-gradient(289deg, #37c5c7, #58cace);
    // background-color: #81d8d0;
    // background-color: #87ceeb;
    background: linear-gradient(92.83deg, #B3E4DF 23.54%, #45BDB1 79.16%);

    @include min-screen(47em) {
        padding: 100px 100px 200px;
    }

    .hero-text-container {
         @include min-screen(47em) {
            margin-left: 4%;
            width: 50%;
            float: left;
            display: inline-block;
            padding: 50px 0 0;
            text-align: left;
        }
    }

    .hero-heading-container {
        max-width: 500px;
        margin: 0 auto;
         @include min-screen(47em) {
            margin: initial;
        }
    }

    h1 {
        font-size: 36px;
        font-weight: 900;
        line-height: 1.17;
        text-transform: none;
        margin: 0 0 20px 0;

        @include min-screen(47em) {
            font-size: 38px;
            margin: 0 0 15px 0;
            color: $text-navy;
        }
    }

    .app-screenshot {
        width: 222px;

        @include min-screen(47em) {
            width: 25%;
            min-width: 250px;
            float: right;
        }
    }

    .desc {
        font-size: 16px;
        line-height: 1.22;
        margin: 0 0 20px 0;

        @include min-screen(47em) {
            font-size: 22px;
            margin: 0 0 33px 0;
            color: $text-navy;
        }

        em {
            font-weight: bold;
        }
    }

    .hero-content {
        position: relative;
        z-index: 15;
        width: 100%;
        padding: 45px 17px;

        @include min-screen(47em) {
            margin: 0;
            max-width: 600px;
            padding: 32px 40px 30px 49px;
            // background-color: rgba(255, 255, 255, 0.8);
            background-color: transparent;
        }
    }

    .cta-container {
        text-align: center;

        @include min-screen(47em) {
            text-align: left;
        }
    }

    .subtext {
        font-size: 14px;
        text-align: center;
        width: 200px;
        margin: 10px auto;

        @include min-screen(47em) {
            width: initial;
            vertical-align: middle;
            text-align: left;
            font-size: 18px;
            line-height: 1.44;
            margin: 10px 2px;
            color: $text-navy;
        }
    }

    .secondary-container {
        text-align: center;

        @include min-screen(47em) {
            text-align: left;
        }
    }

    .above-cta-text {
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 10px 0;

        em {
            color: $el-orange;
        }
    }

    .start-trial-btn {
        @include flat-button($orange4);
        padding: 20px 0;
        display: block;
        margin: 8px auto;
        font-size: 14px;
        line-height: 1.17;
        letter-spacing: 1.2px;
        font-weight: bold;
        width: 320px;
        height: 56px;
        vertical-align: top;

        @include min-screen(47em) {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            line-height: 0.88;
            padding: 20px 0;
            letter-spacing: 1.6px;
            margin: 0 16px 16px 0;
            width: 275px;
            height: 56px;
        }
    }

    .pro-instead-btn {
        @include flat-button($text-navy);
        padding: 13px 0;
        display: block;
        margin: 8px auto;
        font-size: 14px;
        line-height: 1.17;
        letter-spacing: 1.2px;
        font-weight: bold;
        width: 320px;
        height: 56px;

        @include min-screen(47em) {
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            font-weight: bold;
            line-height: 0.88;
            letter-spacing: 1.6px;
            padding: 13px 0;
            margin: 0 16px 16px 0;
            width: 275px;
            height: 56px;
        }

        em {
            font-weight: 300;
            font-size: 10px;
            text-transform: lowercase;

            @include min-screen(47em) {
                font-size: 14px;
            }
        }
    }


    .redeem-invite-btn {
        @include outline-button(white, $text-navy);
        box-shadow: 0 28px 57px 0 rgba(0, 0, 0, 0.1);
        background-color: transparent;
        margin: 8px;
        padding: 10px 25px;
        font-size: 14px;
        line-height: 1.17;
        letter-spacing: 1px;
        font-weight: bold;
        border: 1px solid #263441;
        color: #263441;

        @include min-screen(47em) {
            @include outline-button($text-navy);
            padding: 12px 28px;
            background-color: transparent;
            font-size: 14px;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 1.4px;
        }
    }

}

