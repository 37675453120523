
@import "../../../sass/imports";
@import "../MyAccount/PreferencesForm.scss";

.profile-doctor-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;

    &.open {
        .profile-doctor {
            max-height: calc(100vh - 12%);
        }
    }
}

.profile-doctor-modal-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0; width: 100vw;
}

.profile-doctor {
    overflow: hidden;
    background-color: white;
    padding: 0;

    position: absolute;
    color: $text-gray;

    top: 50%;
    left: 50%;
    width: calc(100% - 40px);
    max-height: 0;
    max-width: 375px;
    @include transition(.333s all ease-in-out);
    @include transform(translate(-50%, -50%));
    @include border-radius(8px);

    .scroll-container {
        overflow-y: auto;
        max-height: 90vh;
        padding: 20px 20px 80px 20px;
    }

    h1 {
        font-size: 24px;
        color: $text-gray;
        margin: 0 0 20px 0;
        text-align: center;

        img {
            width: 125px;
            vertical-align: -6%;
        }
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    h4 {
        font-size: 12px;
        font-weight: bold;
        color: $text-gray;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
    }

    p {
        position: relative;
        font-size: 14px;
        color: $text-gray;
        line-height: 1.1;
        margin: 0 0 10px 0;
        text-align: center;

        em {
            font-weight: bold;
        }
    }

    .off-switch {
        font-size: 14px;
        margin: 10px 40px;
    }

    &.preferences-form .preference-group {
        padding: 0;
        margin: 0 auto 20px;
    }

    > footer {
        text-align: center;
        margin: 40px 0 0 0;

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 20px;
        @include box-shadow(0, 0px, 10px, 0, rgba(0, 0, 0, 0.2));
    }

    .close-modal-btn {
        @include outline-button($text-gray);
    }

    .retry-btn {
        @include flat-button($orange4);
    }
}

