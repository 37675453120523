@import "../../../sass/imports";

.banner-how-it-works {
    text-align: center;
    background: linear-gradient(to top,#57ebc8,#2cd296);
    padding: 55px 0;

    > header {
        color: #324253;
        margin-bottom: 20px;

        h2 {
            font-size: 28px;
            font-weight: 900;
            margin: 0 0 15px;

            @include min-screen(47em) {
                font-size: 36px;
            }
        }

        strong {
            font-size: 22px;
        }

        p {
            font-size: 16px;
            line-height: 1.43;
            max-width: 374px;
            margin: 0 auto;

            @include min-screen(47em) {
                font-size: 18px;
                line-height: 1.33;
            }
        }
    }

    iframe {
        margin: 1em auto 40px auto;
        display: block;
        width: 375px;
        height: 212px;

        @include min-screen(660px) {
            width: 640px;
            height: 362px;
            @include border-radius(10px);
            @include box-shadow(0, 15px, 30px, 0, rgba(0, 0, 0, 0.25));
        }
    }

    .how-step {
        display: inline-block;
        vertical-align: top;
        max-width: 380px;
        text-align: center;
        margin: 20px 5px;

        @include min-screen(47em) {
            text-align: left;
        }

        h4 {
            font-size: 20px;
            color: #324253;
            font-weight: bold;
        }

        p {
            color: #324253;
        }
    }

    .illustration {
        display: block;
        width: 150px;
        height: 100px;
        position: relative;
        margin: 20px auto 0;

        @include min-screen(47em) {
            float: left;
            display: inline-block;
            margin-bottom: 20px;
            height: 150px;
        }

        > i {
            font-size: 75px;
            padding: 35px;
            @include border-radius(100%);
            color: #324253;
        }
    }
}
