@import "../../../../sass/imports";

.panel-planning-tool {
    clear: both;
    padding: 60px 20px 60px 20px;
    max-width: 1000px;
    margin: auto;
    position: relative;

    @include min-screen(550px) {
        padding: 60px 30px 90px 350px;
        min-height: 585px;
    }

    > header {
        text-align: center;
        margin: 0 0 35px 0;
        color: $text-navy;

        h2 {
            font-size: 22px;
            font-weight: 900;
            line-height: 1.27;
            text-transform: none;

            @include min-screen(550px) {
                font-size: 42px;
                line-height: 1.2;
                text-align: left;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.38;

            @include min-screen(550px) {
                font-size: 20px;
                line-height: 1.17;
                text-align: left;
            }
        }
    }

    > footer {
        text-align: center;

        @include min-screen(550px) {
            text-align: left;
        }

        p {
            color: $list-heading-gray;
        }

        .enter-email-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px auto;
            max-width: 500px;

            @include min-screen(1200px) {
                flex-direction: row;
                max-width: none;
            }

            > input {
                flex: 5 1;
                @include border-radius(50px);
                text-align: left;
                padding: 19px;
                width: 100%;
                margin: auto;
                border: solid 0.5px #b4b7c0;
                background: $grayscale2;

                @include min-screen(1200px) {
                    width: auto;
                }

                @include placeholder {
                    color: $grayscale9;
                    opacity: 0.6;
                    font-size: 14px;

                    @include min-screen(47em) {
                        font-size: 16px;
                    }
                }
            }

            > button {
                flex: 1 1;
                white-space: nowrap;
                margin: 10px 0 0 0;
                padding: 19px;
                width: auto;

                @include min-screen(1200px) {
                    margin: 0 0 0 10px;
                }
            }
        }

        .start-trial-btn {
            @include flat-button($orange4);
            padding: 20px 0;
            display: block;
            margin: 8px auto;
            font-size: 14px;
            line-height: 1.17;
            letter-spacing: 1.2px;
            font-weight: bold;
            width: 320px;
            height: 56px;
            vertical-align: top;

            @include min-screen(47em) {
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
                line-height: 0.88;
                padding: 20px 0;
                letter-spacing: 1.6px;
                margin: 0 16px 16px 0;
                width: 275px;
                height: 56px;
            }
        }
    }

    .screenshots {
        margin: 0 auto 20px auto;
        width: 250px;

        @include min-screen(550px) {
            margin: 60px 25px 25px 25px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .spring-carousel {
            width: 250px;
            height: 525px;
            display: block;
        }


        img {
            width: 250px;
            height: 500px;
        }
    }

    ul {
        li {
            color: $text-navy;
            font-size: 14px;
            line-height: 1.29;
            margin: 0 0 30px 0;

            @include min-screen(47em) {
                font-size: 18px;
                color: $list-heading-gray;
            }

            em {
                display: block;
                text-transform: uppercase;
                font-size: 14px;
                color: $list-heading-gray;
                line-height: 1.5;
                letter-spacing: 1.4px;

                @include min-screen(47em) {
                    font-size: 16px;
                }
            }
        }
    }

    .panel-cta-btn {
        @include flat-button($orange4);
        font-size: 10px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1px;
        padding: 13px 21px;

        @include min-screen(47em) {
            font-size: 16px;
            line-height: 0.88;
            letter-spacing: 1.6px;
            padding: 21px 27px;
        }
    }
}
