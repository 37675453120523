@import "../../sass/imports";

.redirect-page {
    padding-top: 180px;
    padding-left: 20px;
    padding-right: 20px;

    .el-fancy-box {
        margin: 40px auto;
        max-width: 500px;
        text-align: center;
    }

    .icon-spinner2 {
        display: block;
        margin: auto;
        font-size: 64px;
        color: $raspberry;
        width: 64px;
    }
}
