@import "../../../sass/imports";

.cpe-accredited {
    background-color: #f0f2f2;
    text-align: center;
    padding: 50px 20px 66px 20px;
    color: $text-navy;

    @include min-screen(47em) {
        text-align: left;
    }

    .cpe-container {
        position: relative;
        max-width: 740px;
        margin: auto;

        @include min-screen(47em) {
            padding: 0 0 0 164px;
        }
    }

    img {
        width: 80px;
        margin: auto;
        display: block;

        @include min-screen(47em) {
            position: absolute;
            top: -30px;
            left: -35px;
            width: 172px;
            margin: 12px 0 0 0;
        }
    }

    h2 {
        font-size: 28px;
        font-weight: 900;
        line-height: 1.27;
        margin: 20px 0 38px 0;
        @include min-screen(47em) {
            font-size: 40px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.38;
        @include min-screen(47em) {
            font-size: 24px;
        }
    }
}
