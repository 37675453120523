@import "../../../sass/imports";

.activities-tracker-container {
    border: 1px solid #888;
    border-radius: 16px;
    display: flex;
    padding: 10px;
    margin: 10px 20px 0 20px;

    @include min-screen(47em) {
        max-width: 50%;
    }

    .activity-tracker-item {
        display: flex;
        flex-direction: column;

        header {
            display: flex;
            align-items: flex-start;

            .provider-logo {
                max-width: 25px;
            }

            .provider-title {
                padding-left: 10px;

                h6 {
                    font-weight: 400;
                }
            }
        }

        .activity-tracker-info {
            margin-left: 35px;
            font-size: 20px;

            span {
                font-weight: bold;
            }
        }
    }
}
