
@import "../../../sass/imports";

.basic-meals-banner {
    @include transition(.333s all linear);
    border-radius: 0 !important;
    border: 0;
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    /*top: calc(100% - 17.5em);*/
    top: auto;
    background-color: $text-gray;
    color: white;
    max-height: 0;
    @include box-shadow(0, -5px, 10px, 0, rgba(0, 0, 0, 0.25));

    &[data-drawer="true"] {
        max-height: 25em;
    }

    .inner-container {
        padding: 10px 0;
        max-width: 600px;
        margin: auto;
        width: 100%;
    }

    h3 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        margin: 10px 0 0px 0;
    }

    p {
        font-size: 14px;
        text-align: center;

        em {
            font-weight: bold;
        }
    }

    a {
        text-decoration: underline;
        color: $mint-green3;

        &:visited {
            color: darken($super-light-gray, 10%);
        }
    }

    footer {
        padding: 10px;
        text-align: center;
    }

    .yes-btn {
        @include flat-button($orange4);
        margin: 5px;
    }

    .no-btn {
        @include outline-button(white, $text-gray);
        margin: 5px;
        padding: 8px 15px;
    }
}
