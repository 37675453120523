@import "../../../sass/imports";

.article-page {
    color: $text-navy;
    background-color: white;
    margin: 0;

    @supports (padding-top: constant(safe-area-inset-top)) {
        margin-top: constant(safe-area-inset-top);
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        margin-top: env(safe-area-inset-top);
    }

    > h1 {
        text-align: center;
        font-size: 36px;
        margin: 1em auto 0em auto;
        max-width: 680px;
        padding: 0 20px;
    }

    .by-line {
        max-width: 680px;
        margin: 20px auto;
        padding: 0 20px;

        font-size: 18px;

        .avatar {
            margin: 0 15px 0 0;
            vertical-align: middle;
            display: inline-block;
        }

        img.avatar {
            width: 45px;
            height: 45px;
            @include border-radius(45px);
        }

        i.avatar {
            font-size: 35px;
            vertical-align: -15%;
        }

        .publish-date {
            font-size: 14px;
            color: $bluey-gray;

            &:before {
                content: '•';
                margin: 0 1em;
                display: inline-block;
            }
        }
    }

    .article-rich-text {
        max-width: 680px;
        margin: 0 auto 80px auto;
        padding: 0 20px;

        @include min-screen(700) {
            padding: 0;
        }
    }
}

.article-rich-text {
    color: $text-navy;
    font-size: 18px;

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 12px;
    }

    a {
        color: $raspberry;
        text-decoration: underline;

        &:visited {
            color: darken($raspberry, 10%);
        }
    }

    p {
        margin: 0.75em 0;
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    del {
        text-decoration: line-through;
    }

    ul li, ol li {
        margin-left: 1.5em;
    }

    ul {
        list-style-type: disc;
        margin: 16px 0;

        ul {
            list-style-type: circle;
            margin: 0;

            ul {
                list-style-type: square;
                margin: 0;
            }
        }
    }

    ol {
        list-style-type: decimal;
        margin: 16px 0;

        ol {
            list-style-type: upper-alpha;
            margin: 0;

            ol {
                list-style-type: upper-roman;
                margin: 0;

                ol {
                    list-style-type: lower-alpha;
                    margin: 0;

                    ol {
                        list-style-type: lower-roman;
                        margin: 0;

                        ol {
                            list-style-type: lower-greek;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    pre[data-block="true"] {
        color: white;
        background-color: $text-gray;
        margin: 10px;
        padding: 10px;
        border: 1px dashed $bluey-gray;
    }

    .align-right {
        text-align: right;
    }

    .align-left {
        text-align: left;
    }

    .align-center {
        text-align: center;
        margin: auto;
    }

    .align-justify {
        text-align: justify;
    }

    .float-left {
        float: left;
        margin: 10px 10px 10px 0;
    }

    .float-right {
        float: right;
        margin: 10px 0 10px 10px;
    }

    .clear-float {
        clear: both;
    }

    .size-small, .size-medium, .size-large {
        img, iframe {
            width: 100%;
        }

        iframe {
            width: 100%;
        }
    }

    .size-small {
        width: 250px;
        max-width: 100%;
    }

    .size-medium {
        width: 500px;
        max-width: 100%;
    }

    .size-large {
        width: 900px;
        max-width: 100%;
    }
}

