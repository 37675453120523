
@import "../../sass/imports";

.admin-tools {
    background-color: white;

    .permission-denied {
        padding: 40px;
        max-width: 300px;
        margin: 160px auto 40px auto;

        background-color: $text-gray;
        text-align: left;
        @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));
        color: white;
        font-weight: bold;
        font-size: 16px;
        text-align: center;

        &:before {
            display: block;
            @include icon-font;
            content: $icon-warning1;
            font-size: 45px;
            margin: 10px auto;
            color: $orange3;
        }
    }
}
