
@import "../../../../sass/imports";

.feed-coachmark-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    line-height: 24px;

    @include max-screen(47em) {
        margin: 0 8px;
    }

    &.open {
        .feed-coachmark {
            max-height: calc(100vh - 40px);
            padding: 40px 20px;

            @supports (max-height: constant(safe-area-inset-top)) {
                max-height: calc(100vh - 150px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
            }

            @supports (max-height: env(safe-area-inset-top)) {
                max-height: calc(100vh - 150px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
            }
        }
    }

    h1 {
        color: #ffffff;
        font-size: 26px;
        margin: 0 0 20px 0;

        @include max-screen(47em) {
            text-align: center;
            font-size: 20px;
        }

        em {
            text-transform: capitalize;
            font-weight: 700;
        }

        img {
            width: 125px;
            vertical-align: -6%;
        }
    }
}

.quick-tour {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h1 {
            flex: 1;
            margin: 0;
            font-size: 26px;
            color: #ffffff;
            text-align: center;
        }
        
        .close-btn {
            border: none;
            background: none;
            color: #ffffff;

            .icon-close-x {
                font-size: 18px;
                padding: 8px;
            }
        }
    }

    .video-container {
        width: 800px;
        height: auto;

        @include max-screen(47em) {
            width: 100%;
            height: auto;
        }
    }

    footer {
        .el-large-btn {
            font-size: 14px;
            width: 271px;
        }
    }
}

.feed-coachmark-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));

    @include max-screen(47em) {
        width: 100%;
    }

    .nutrients {
        display: flex;
        width: 100%;

        @include max-screen(47em) {
            flex-direction: column;
        }
    }

    .what-to-know {
        font-size: 16px;
        position: relative;

        ul {
            list-style: none;
            
            li {
                position: relative;
                padding-left: 25px;
                padding-bottom: 10px;
            
                &:before {
                  content: $icon-check-circle;
                  font-family: "eatlove";
                  color: #D8D8D8;
                  position: absolute;
                  left: 0;
                  top: 1px;
                }
            }
        }
    }

    .iframe-container {
        position: absolute;
        width: 240px;
        height: auto;
        right: 0;
        bottom: 0;
        @include transform(translate(calc(100% + 40px), 0));
        background: #203152;
        border: 1px solid #203152;
        border-radius: 4px;
        cursor: pointer;

        @include max-screen(1130px) {
            width: 20vw;
        }

        @include max-screen(47em) {
            display: none;
        }

        .title {
            text-align: center;
            margin: 8px auto;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
        }


        .iframe-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            z-index: 2;
        }
    }

    iframe, .coachmark-video-preview {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        aspect-ratio: 16 / 9;
        border: 0;
    }

    .coachmark-video-preview {
        background: linear-gradient(0deg, rgba(89, 89, 89, 0.6), rgba(89, 89, 89, 0.6)), url(https://static.chewba.info/images/2613d721-170e-49e8-a540-99c88c20de3e.jpg);
        background-size: cover;
        background-position: center;
    }

    .coachmark-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));

        .icon-spinner2 {
            display: inline-block;
            font-size: 40px;
            color: $raspberry;
        }
    }

    footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 8px;

        i {
            color: #ffffff;
        }

        .el-large-btn {
            font-size: 14px;
            width: 250px;
        }

        .el-raspberry-btn {
            width: 115px;

            i {
                margin-right: 3px;
            }
        }
    }
}

.feed-coachmark {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 0 40px;
    min-height: 500px;
    width: 800px;
    overflow-y: auto;
    @include transition(.333s all ease-in-out);
    @include border-radius(8px);
    color: #A8ACBD;
    text-align: left;
    align-items: flex-start;
    background: #203152;

    @include max-screen(1130px) {
        width: 50vw;
    }

    @include max-screen(47em) {
        min-height: 50vh;
        width: unset;
    }

    .separator {
        width: 100%;
        border: none;
        height: 1px;
        background: #6A779B;
        position: relative;
        flex-shrink: 0;

        .your-prescription {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(0, -50%);
            background: #64779B;
            border: 1px solid #64779B;
            border-radius: 8px;
            padding: 4px 16px;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
        }
    }

    h3 {
        font-size: 24px;

        @include max-screen(47em) {
            font-size: 22px;
        }
    }

    h5 {
        font-size: 20px;

        @include max-screen(47em) {
            font-size: 18px;
        }
    }

    h3, h5 {
        color: #ffffff;
        margin-bottom: 10px;
    }

    .description {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 18px;

        @include max-screen(47em) {
            font-size: 16px;
        }

        p, button {
            margin-top: 10px;
        }
    }
}

