
@import "../../../../sass/imports";

.grid-meal-cell {
    cursor: pointer;
    min-height: 127px;
    width: 100%;
    border: 1px solid white;
    @include border-radius(6.5px);
    @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.1));

    &[data-hover="true"] {
        .no-meal-scheduled, .log-meal, .grid-meal-draggable {
            border: 1px solid $orange4;
        }
    }

    .no-meal-scheduled, .log-meal {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;

        width: calc(100% - 0px);
        @include border-radius(4px);
        background-image: linear-gradient(307deg, #979caf, #d3d3d3);
        border: none;

        h3 {
            font-size: 14px;
            font-weight: 800;
            color: white;
            text-align: center;
            padding: 37px 0 10px 0;
            max-width: 120px;
            margin: auto;
            line-height: 1.29;
            letter-spacing: 1px;
            text-transform: uppercase;
            position: relative;
            z-index: 5;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
        }

        > footer {
            position: absolute;
            z-index: 5;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;
        }

        > .current-meal {
            display: flex;

            .add-meal-btn {
                background-color: $raspberry;
            }

            .log-meal-btn {
                background-color: $mint-green3;
            }
        }
    }

    .log-meal {
        h3 {
            // padding: 60px 0 10px 0 !important;
        }
    }

    .add-meal-btn, .log-meal-btn {
        border: none;
        display: block;
        margin: auto;
        width: 86px;
        font-size: 8px;
        font-weight: bold;
        padding: 7px;
        text-transform: uppercase;
        color: white;
        background-color: $orange4;
        @include border-radius(40px);
        box-shadow: 0 11px 11px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(210, 134, 44, 0.2);
    }

    .log-meal-btn {
        background-color: $raspberry;
    }
}
