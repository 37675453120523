
@import "../../../sass/imports";
@import './Notes.scss';
@import "../../pro/components/Widgets/Markdown.scss";

.daily-log-extras {

    .water-btns, .with-units {
        margin: 23px 0 8px 20px;
    }

    .daily-log-notes {
        .edit-notes {
            padding: 20px 0 0 20px;

            > footer {
                display: inline-block;
            }
        }

        textarea, .markdown {
            margin: 20px 20px 0 0;
            max-height: 400px;
        }

        textarea{
            width: calc(100% - 40px);
        }
    }

    .full-water-btn, .add-water-btn, .empty-water-btn {
        background: none;
        border: none;
        padding: 6px;
    }

    .water-intake > p {
        text-align: right;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        color: $text-navy;
        max-width: calc(268px + 79px);
        text-align: left;
        margin-left: 20px;
    }

    .biometrics {
        position: relative;

        > p {

        }

        .previous-entry {
            padding: 10px 0px 0 0;
            font-size: 14px;
            color: #425569;

            > p {
                display: inline-block;
                vertical-align: top;
                padding-right: 5px;
            }

            div {
                display: inline-block;
                text-align: left;

                p {
                    display: inline-block;

                    &:first-child {
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }
            }


        }
    }

    .with-units {
        white-space: nowrap;


        .add-notes-btn {
            border: 1px solid #a30063;
            color: #a30063;
        }

        input {
            display: inline-block;
            width: 140px;
            margin: 25px 10px;
            font-size: 36px;
            font-weight: 800;
            text-align: center;
            color: $text-navy;
            padding: 0 0 0 5px;
            -webkit-appearance: none;
            border-width: thin;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            @include placeholder {
                font-size: 14px;
                font-weight: normal;
                text-align: left;
            }
        }


        button {
            background: none;
            border: none;
            color: $raspberry;
            font-weight: bold;
        }


        label {
            display: inline-block;
            text-align: left;
            color: $text-navy;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            width: auto;
            margin-right: 20px;

            &:first-child {
                font-size: 36px;
            }

        }
    }
}
