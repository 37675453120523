
@import "../../../sass/imports";

.spring-carousel {
    overflow: hidden;
    user-select: none;
    -webkit-overflow-scrolling: touch;
    position: relative;

    > .spring-carousel-item {
        position: absolute;
        // width: calc(100vw - 80px);
        // max-width: 295px;
        will-change: transform;
    }

    > footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;

        i {
            font-size: 8px;
            display: inline-block;
            margin: 0 3px;
        }

        .icon-radio-selected {
            color: $dark-slate-gray;
        }

        .icon-radio-unselected {
            color: $dark-slate-gray;
        }
    }
}

