
@import "../../../sass/imports";

.app-home-screen {
    width: 100vw;
    height: 100vh;

    .app-home-screen-outer-container {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }
    .app-home-screen-inner-container {
        background-image: url(https://static.chewba.info/images/hp-08-05/bg-slide-1.1.jpg);
        background-position: center;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        position: relative;
        margin: 0;
        padding: 0;
    }

    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background: linear-gradient(180deg, #F1F2F5 31.99%, rgba(241, 242, 245, 0.927083) 59.03%, rgba(241, 242, 245, 0) 100%);
        min-height: 325px;

        @supports (padding-top: constant(safe-area-inset-top)) {
            padding-top: constant(safe-area-inset-top);
        }
        @supports (padding-top: env(safe-area-inset-top)) {
            padding-top: env(safe-area-inset-top);
        }

        img {
            width: 200px;
        }

        h2 {
            margin: 20px;
            font-weight: 900;
            font-size: 24px;
            line-height: 38px;
            color: $dark-slate-gray;
        }

        p {
            text-transform: uppercase;
            font-size: 18px;
            color: $dark-slate-gray;
            margin-bottom: 15px;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        text-align: center;
        background-color: #F1F2F5;

        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
            padding-bottom: constant(safe-area-inset-bottom);
        }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
            padding-bottom: env(safe-area-inset-bottom);
        }
    }

    .sign-in-btn {
        @include flat-button($orange4);
        font-size: 16px;
        padding: 15px 50px;
        font-weight: 800;
        letter-spacing: 1.5px;
        width: 100%;
        max-width: 270px;
        margin: 10px;
    }

    .sign-up-btn {
        @include flat-button($text-gray);
        font-size: 16px;
        padding: 15px 50px;
        font-weight: 800;
        letter-spacing: 1.5px;
        width: 100%;
        max-width: 270px;
        margin: 10px;
    }
}
