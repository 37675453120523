@import "../../../sass/imports";

.banner-testimonials {
    background-color: #f0f2f2;
    color: $text-navy;
    font-size: 14px;
    padding: 60px 0;
    position: relative;

    > header {
        h2 {
            font-size: 22px;
            font-weight: 900;
            line-height: 1.27;
            text-align: center;
            @include min-screen(47em) {
                font-size: 42px;
                line-height: 1.4;
            }
        }

        p {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.27;

            text-align: center;
            margin: 0 0 33px 0;
        }
    }

    .scroller {
        white-space: nowrap;
        overflow-x: auto;
        margin: 0 20px 33px;
        scroll-snap-type: x mandatory;
        scroll-padding: 50%;
        padding: 0 12px;
        position: relative;
        z-index: 0;

        &::-webkit-scrollbar {
           display: none;
        }

        @include min-screen(47em) {
            margin: 0 120px 33px;
        }
    }

    .card {
        width: 260px;
        height: 350px;
        white-space: normal;
        margin: 8px;
        display: inline-block;
        vertical-align: top;
        padding: 16px 18px;
        background-color: white;
        scroll-snap-align: center;
        @include border-radius(6px);
    }

    .icon-star-full {
        color: #e19e28;
    }

    .date, .author {
        color: $yet-another-gray2;
    }

    .subject {
        color: $text-navy;
        font-weight: bold;
    }

    .quote {
        line-height: 1.43;
        color: $text-navy;
    }

    .scroll-left-btn, .scroll-right-btn {
        position: absolute;
        z-index: 5;
        top: 228px;
        width: 28px;
        height: 28px;
        @include border-radius(40px);

        border: none;
        background-color: white;
        font-size: 16px;
        padding: 3px;

        @include min-screen(47em) {
            width: 80px;
            height: 80px;
            font-size: 20px;
            padding: 30px;
            margin: 16px;
        }
    }

    .scroll-left-btn {
        left: 0;
    }

    .scroll-right-btn {
        right: 0;
    }

    > footer {
        text-align: center;
        margin: 33px 0 0 0;

        button {
            @include flat-button($orange4);
            font-size: 10px;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: 1px;
            padding: 13px 21px;

            @include min-screen(47em) {
                font-size: 16px;
                line-height: .88;
                letter-spacing: 1.6px;
                padding: 20px 85px;
            }
        }
    }
}
