@import "../../../sass/imports";

.banner-best {
    padding: 3em 0 5em 0;
    width: 100%;
    text-align: center;
    background-color: white;
    color: $text-navy;

    h2 {
        font-size: 28px;
        font-weight: 900;
        line-height: 1.27;

        @include min-screen(47em) {
            font-size: 36px;
            line-height: 1.4;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.38;
        margin: 20px auto;
        max-width: 350px;

        @include min-screen(47em) {
            font-size: 18px;
            line-height: 1.33;
            max-width: 600px;
        }
    }

    .best-image {
        background-size: cover;
        background-position: center;
        width: 170px;
        height: 170px;
        @include border-radius(100%);
        display: block;
        margin: 0 auto 15px auto;
    }

    .dietitian {
        width: 220px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            color: $text-gray;
        }

        h4 {
            font-size: 14px;
            line-height: 1.71;
            font-weight: normal;
            color: $yet-another-gray2;
        }

        h5 {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.29;
            color: $text-gray;

            @include min-screen(47em) {
                font-size: 18px;
                line-height: 1.33;
            }
        }
    }

    // Sarah Koszyk, MA, RDN
    #best1 {
        background-image: url(https://resize.chewba.info/s_s/o_1/h_340/images/e67d19b8-266c-48aa-b453-336a5b17f6a5.jpg);
        background-position-x: 30%;
    }

    // Jenna Corbin, MS, RD, CSSD, CISSN, CLT, PES/CES
    #best3 {
        background-image: url(https://resize.chewba.info/s_s/o_1/w_340/images/c4a6e170-8b0a-482c-9226-949d4488903a.jpg);
    }

    // Sheryl Akagi Allen, RD, PhD
    #best4 {
        background-image: url(https://resize.chewba.info/s_s/o_1/w_340/images/85738f3c-8ff7-4373-82d7-5cc7833222c7.jpg);
        background-position-y: 0%;
    }

    // Adiana Castro MS, RDN, CDN, CLT
    #best5 {
        background-image: url(https://resize.chewba.info/s_s/o_1/h_340/images/860cfe3f-f763-44cf-9a8d-fcdad083156a.jpg);
        background-position-x: 66%;
    }

    // Alanna Cabrero, MS, RDN, CDN
    #best6 {
        background-image: url(https://resize.chewba.info/s_s/o_1/w_340/images/b6e725bb-6ba8-4e78-9e13-150af101843c.jpg);
        background-position-y: 0%;
    }

    // Sahar Berjis RD, MPH, CDN
    #best7 {
        background-image: url(https://static.chewba.info/images/d5655bc8-ef4a-4679-b66b-44ad3d3df138.png);
        background-position-x: 23%;
    }

    // Tracy Owens, MPH, RD, CSSD, LDN
    #best8 {
        background-image: url(https://resize.chewba.info/s_s/o_1/w_340/images/d8958e77-ac2d-45b6-a975-a4a1bd2b609a.jpg);
        background-position-y: 0%;
    }
}
