@import "../../../sass/imports";

.edit-daily-log-modal-container {
    background-color: #fff;
    left: 50%;
    max-width: 600px;
    min-width: 300px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: calc(100vw - 40px);
    border-radius: 10px;

    max-height: calc(100vh - 10px);
    @supports (max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))) {
        max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    }

    @supports (max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))) {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }

    .modal-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .close-btn {
            position: absolute;
            top: 0; right: 0;
            z-index: 10;
            border: none;
            background: none;
            font-size: 10px;
            padding: 15px;
        }
    }

    > footer {
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        padding: 15px 0;

        .cancel-btn, .save-btn {
            display: inline-block;
            font-size: 20px;
            height: 40px;
            margin: 0;
            width: 33%;
            border: none;
            -webkit-border-radius: 40px;
            -spec-border-radius: 40px;
            border-radius: 40px;
            color: #fff;
            font-weight: normal;
            letter-spacing: 1.2px;
            line-height: 1.4;
            margin: 15px 7px;
            min-width: 100px;
            text-transform: uppercase;
        }

        .cancel-btn {
            background-color: #fff;
            border: 2px solid #b5bec8;
            color: #b5bec8;
        }

        .save-btn {
            background-color: #a30063;
            color: white;

        }
    }
}


.edit-daily-log-modal-body {
    padding: 40px 20px 80px;

    > h2 {
        font-size: 24px;
    }

    > .date-label {
        font-size: 18px;
    }

    &.edit-weight-modal {
        text-align: center;
    }

    .error_msg {
        font-weight: bold;
        font-size: 14px;
        color: red;
    }
}