@import "../../../sass/imports";

.collections-page {
    padding-top: 160px;
    min-height: 100vh;

    background-color: white;

    @include max-screen(47em) {
        padding-top: 105px;
    }
}
