@import "../../../../sass/imports";

.quick-log-food {
    display: flex;

    > p {
        text-transform: uppercase;
        margin-right: 20px;
        flex-grow: 1;

        em {
            font-weight: normal;
        }
    }
}

.quick-log-ai-confirmation {

    &.el-modal-body-container {
        padding: 30px 30px 100px 30px;
        min-height: 50vh;
    }

    .el-labeled-input {
        > label {
            margin: 5px 5px 0 5px;
        }
    }

    .item-container {
        margin: 0 0 40px 0;
    }

    .food-unit-selector {
        margin: 0;
    }

    .remove-food-btn {
        border: none;
        background: none;
    }

    .el-alert {
        margin: 10px;
    }

    .el-link-raspberry-btn {
        line-height: 22px;
        text-decoration: none;
        vertical-align: bottom;
        padding: 0 10px;
        margin: 0;
    }

    .el-popup {
        vertical-align: bottom;
        line-height: 16px;
        margin: 0 10px 0 0;

        .dropdown-btn {
            border: none;
            background: none;
            min-height: 25px;
        }
    }

    .food-warning {
        color: #bb130f;
        margin-left: 5px;
        font-size: 18px;
        line-height: 18px;
        vertical-align: bottom;
    }

    .food-not-found {
        width: 200px;
        padding: 0 10px;

        footer {
            text-align: center;
            padding: 20px 0 10px 0;
        }
    }
}
