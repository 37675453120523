
@import "../../../sass/imports";

.customizer-page {
    background-color: #fafafa;
    padding: 1px;

    .meal-plan-customizer {
        margin: auto;
        > h1 {
            max-width: 1000px;
            margin: 20px auto;

            @include min-screen(1000px) {
                margin: 20px 10px;
            }
        }
    }

    .eatlove-logo-header {
        display: block;
        margin: 20px auto;
        max-width: 150px;
    }

    .customizer-scroll-container {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        padding-top: 35px;
    }

    .primary-actions {
        position: fixed;

        @include min-screen(47em) {
            z-index: 2;
            top: 55px;
        }
    }

}
