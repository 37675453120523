
@import "../../sass/imports";

.daily-log-modal-container {

    > header {

        @include max-screen(47em) {
            h2 {
                position: relative;
                z-index: 20;
            }

            &:before {
                content: ' ';
                position: absolute;
                z-index: 1;
                top: -98px;
                left: 50%;
                width: 145vw;
                max-width: 125vw;
                height: 205px;
                z-index: 0;

                @include transform(translate(-50%,calc(-100% + 210px)));
                @include border-radius(50%);

                @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
                @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

                height: 250px;
                @include border-radius(55%);
            }
        }

        @include min-screen(47em) {
            &:after {
                border-bottom: 15px solid $dark-slate-gray !important;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                content: " ";
                height: 0;
                position: absolute;
                right: 130px;
                top: 47px;
                transform: translate(50%);
                width: 0;

            }
        }
    }

    &.el-modal1-container {
        background: none;
        box-shadow: none;

        > header {
            z-index: 2;

            @include min-screen(47em) {
                max-width: 800px;
                left: 50%; right: auto;
                @include transform(translate(-50%, 0));

                h2 {
                    font-size: 15px;
                }
            }

            @include max-screen(47em) {
\                .el-modal-back-btn {
                    top: 15px;
                }

                h2 {
                    margin: 15px auto 20px;
                }
            }
        }



    }

    .daily-log-scroll-container {
        background-color: white;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));

        &::-webkit-scrollbar {
           @include max-screen(47em) {
                display: none;
            }
        }

        @include min-screen(47em) {
            top: 10px;
            bottom: auto;
            left: 50%; right: auto;
            @include transform(translate(-50%, 0));
            min-height: 0;
            max-width: calc(100vw - 30px);
            max-height: calc(100vh -  100px);
            border-radius: 10px;
        }

        .el-modal1-body-container {
            padding: 0px 10px;
        }
    }

    .date-selector-popover {
        @include min-screen(47em) {
            .dropdown-btn {
                width: 260px;
                font-weight: 700;
            }
            .next-date-btn, .prev-date-btn  {
                //Hover events only exist on desktop
                &:hover {
                    background-color: white;
                    color: $raspberry;
                }

            }
        }

        .dropdown-btn {
            font-weight: 700;
        }
    }
}

.daily-log-page {
    max-width: 780px;
    min-height: 35vh;
    width: 100vw;
    padding: 0 0 100px 0;

    position: relative;
    margin: auto;

    @include min-screen(47em) {
        width: calc(100vw - 20px);
    }

    .scroll-left-btn, .scroll-right-btn {
        position: absolute;
        top: 10px;

        color: $bluey-gray;
        font-size: 22px;
        border: none;
        background: none;

        @include max-screen(47em) {
            display: none;
        }
    }

    .scroll-left-btn {
        left: 20px;
    }

    .scroll-right-btn {
        right: 20px;
        width: 50px;
        height: 50px;
        margin-top: -15px;
    }

    .nutrition-analysis {
        padding: 0 0 0 20px;
        margin: 125px 0 20px;

        @supports (top: env(safe-area-inset-top)) {
            margin-top: calc(125px + env(safe-area-inset-top));
        }

        @include min-screen(47em) {
            margin: 30px 80px 30px 60px;
        }

        .macro-left, .macro-over {
            color: white;
        }

        .macro-left, .macro-over {
            em {
                font-size: 10px;
                text-transform: uppercase;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;

                &[data-out-of-range="true"] {
                    color: $el-orange;
                }

            }
        }
    }

    .calorie-budget {
        color: white;
        display: inline-block;
        margin: 0 20px 0 0;

        i {
            vertical-align: middle;
        }

        em {
            display: inline-block;
            width: 55px;
            text-align: right;
            font-size: 16px;
        }

        .consumed {
            border-bottom: 1px solid white;
            padding-bottom: 4px;

            &[data-mode="current"] {
                cursor: pointer;
            }

            em:before {
                content: '−';
                float: left;
            }
        }

        .remaining em {
            font-size: 20px;
            font-weight: bold;
        }

        li {
            font-size: 10px;
            line-height: 1;
        }
    }

    .macros-pie-chart .macros-legend {
        color: white;
    }

    .macros-pie-chart {
        .chart {
            margin: 0 13px 0 0px;
        }
    }

    .meals {
        margin: 40px 0 0 0;
    }

    .nutrition-export-container {
        text-align: center;
        max-width: 640px;
        margin: 60px auto 30px auto;

        @include min-screen(47em) {
            text-align: right;
        }
    }

    .with-header {
        max-width: 600px;
        margin: 30px auto 30px auto;
        // border: 1px dashed blue;

        > header {
            background-color: #ebebeb;
            height: 50px;
            padding: 0 20px;
            color: $text-gray;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .meal-type-title {
                font-size: 14px;
                font-weight: 900;
                letter-spacing: 1px;
            }

            .header-buttons {
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;

                .show-nutrition-btn, .add-food-btn2 {
                   background: none;
                   border: none;
                   color: $text-gray;
                   font-size: 11px;

                   width: 50px;
                   height: 50px;

                   i {
                       border: 1px solid $text-gray;
                       @include border-radius(20px);
                       padding: 5px;
                       width: 22px;
                       height: 22px;
                   }
               }
            }

            .total-calories {
                margin-left: auto;
                font-size: 13px;
                text-align: right;

                em {
                    font-size: 16px;
                    font-weight: bold;
                }

                .lowercase-text {
                    text-transform: none;
                    display: inline-block;
                }
            }

        }

        > footer {
            padding: 20px 0 0 20px;

            .edit-notes {
                &.empty-notes {
                    display: inline-block;
                }

                &.non-empty-notes {
                    margin-top: 25px;

                    .notes-markdown {
                        display: inline-block;
                        margin-right: 10px;
                        max-width: 90%;
                    }


                    > footer {
                        display: inline-block;
                    }
                }
            }

        }

        &[data-mode="current"] {
            > header {
                background-image: linear-gradient(rgba(44,210,150,.15),rgba(44,210,150,.15));
            }

        }

        &[data-mode="current"], &[data-mode="future"] {
            > header {
                .total-calories {
                    color: $el-green4;
                }
            }

        }

        &[data-mode="future"] {

            .daily-log-meal {
                .row-text, .food-units-selector-outer { color: $el-green4; }
                > footer > p { color: $el-green4; }
                .remove-log-btn i, .show-nutrition-btn i { color: $el-green4; border-color: $el-green4;}
            }
        }

    }

    .meal-type-notes {
        padding: 20px;
    }

    .meal-list {
        margin: 15px 0;
        width: calc(100% - 0px);
    }

    .photos-list {
        margin: 15px 0;
        padding: 14px 20px 0px;

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 19px 0 0;

            &[data-camera-icon="true"] {
                margin: 0;
            }

            &.bar-code-icon-container {
                .scan-barcode-btn {
                    color: $raspberry;
                    border: none;
                    background: none;
                    font-size: 25px;
                    padding: 14px 16.5px 13px 18.5px;

                    i {
                        display: inline-block;
                    }
                }
            }

            &[data-inactive="true"] {
                .meal-photo {
                    @include filter(saturate(0) brightness(1) grayscale(1));
                }
            }
        }

        img, .meal-photo {
            width: 62px;
            height: 62px;
            object-fit: cover;
            cursor: pointer;
            background-size: cover;
            background-position: 50%;
            @include border-radius(4px);
        }

        @include max-screen(47em) {
            overflow-x: clip;
        }
    }

    .add-photo-btn {
        position: relative;
        display: inline-block;
        overflow: hidden;

        button {
            width: 62px;
            height: 62px;
            border: none;
            background: none;
            color: $raspberry;
            font-size: 24px;
            padding: 8px;
            display: inline-block;
            @include border-radius(4px);
        }

        input[type="file"] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 2;
        }

        .error {
            display: none;
        }

        .icon-spinner {
            display: inline-block;
        }
    }

    .add-food-btn, .add-notes-btn {
        font-size: 11px;
        font-weight: 800;
        line-height: 1.27;
        color: $raspberry;
        padding: 8px 13px;
        background: none;
        margin: 0 17px 0 0;
        text-transform: uppercase;
        @include  border-radius(40px);
        @include transition(all .333s ease-in-out);
    }

    .add-food-btn {
        border: 1px solid $raspberry;
        color: white;
        background-color: $raspberry;
    }

    .add-notes-btn {
        color: $raspberry;
        border: 1px solid $raspberry;
    }

    .quick-log-btn {
        padding: 3px 2px 3px 13px;
    }

    .ai-false-btn {
        background: white;
        color: $el-raspberry;
        margin: 0 2px;
        padding: 4px 6px;
        display: inline-block;
    }

    .edit-link-button {
        background: none;
        border: none;
        color: $raspberry;
        font-weight: bold;
        font-size: 14px;
    }
}

.daily-log-photo-modal {
    position: absolute;
    top: 0px;
    left: 50%;
    @include transform(translate(-50%, 0));
}

.daily-log-photo-modal-container {
    max-height: 100vh;
    max-width: 100vw;
    overflow-x: auto;
    padding: 200px 0 50px 0;

    &::-webkit-scrollbar {
        display: none;
    }

    .veil-container {
        position: relative;
    }

    .veil {
        position: absolute;
        top: 0; height: 188px;
        left: 0; right: 0;
        width: 100%;
        background-image: linear-gradient(to top, rgba(188, 188, 188, 0), #fbfafa);
    }

    img {
        max-width: 300px;

        @include min-screen(47em) {
            max-width: 450px;
        }
    }

    .close-photo-modal-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        color: $josh-blue-gray;
        background: none;
        border: none;
        z-index: 5;
    }

    .delete-photo-btn {
        position: absolute;
        z-index: 5;
        top: 17px;
        left: 23px;
        padding: 10px;

        border: none;
        background-color: $raspberry;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(157, 0, 95, 0.2);
        color: white;
        font-size: 9px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        @include border-radius(20px);
    }
}

.calorie-modal {
    color: $el-grayish-blue;
    padding: 20px 30px;

    li {
        margin-bottom: 30px;
        cursor: pointer;

        .el-radio-btn {
            line-height: 24px;
            vertical-align: middle;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0px;
        }

        p {
            font-size: 18px;
            margin-left: 30px;
        }

    }

    header {
        font-size: 18px;
        font-weight: 700;
    }

    > div {
        padding-bottom: 20px;
    }
}


.daily-log-scroll-container {

    &:not([data-hide-nutrition="true"]) {
        &:before {
            content: ' ';
            position: absolute;
            z-index: 1;
            top: 97px;
            left: 50%;
            width: 145vw;
            max-width: 1118px;
            height: 205px;
            z-index: 0;

            @supports (top: env(safe-area-inset-top)) {
                top: calc(97px + env(safe-area-inset-top));
            }

            @include min-screen(47em) {
                top: 0;
            }

            @include transform(translate(-50%, calc(-100% + 210px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

            @include transform(translate(-50%, calc(-100% + 120px)));
        }
    }

    &[data-hide-nutrition="true"] {
        &:before {
            @include transform(translate(-50%, calc(-100% + 140px)));

            @include min-screen(47em) {
                @include transform(translate(-50%,calc(-100% + 60px)));
            }
        }

        .nutrition-analysis {
            visibility: hidden;

            margin: 25px 0 20px;

            @supports (top: env(safe-area-inset-top)) {
                margin-top: calc(25px + env(safe-area-inset-top));
            }

        }

        .show-nutrition-btn {
            display: none;
        }

        .daily-log-page .meals {
            @include min-screen(47em) {
                margin: 80px 0 0 0;
            }
        }

        .total-calories, .calories, .biometrics {
            display: none;
        }
    }

}