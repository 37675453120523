
@import "../../../sass/imports";
@import "../../pro/components/Widgets/Markdown.scss";

.daily-log-notes {

    p {
        color: $text-navy;
        font-size: 13px;
        margin: .75em 0;

        em {
            font-weight: bold;
            display: block;
        }
    }

    .notes-markdown {
        color: $text-navy;
        display: inline-block;
        margin-right: 10px;
        max-width: 90%;

        h4 {
            font-size: 13px;
            margin: 0 0 .75em 0;

            font-weight: bold;
            display: block;
        }

        .markdown {
            margin: 0 0 20px 0;
        }
    }

    textarea {
        background-color: transparent;
        border: solid 0.5px $bluey-gray;
        font-size: 13px;
        color: darken($raspberry, 10%);
        padding: 4px 8px;
        width: 100%;
        height: 150px;
        margin: 0 0 10px 0;

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        &:disabled {
            opacity: 0.5;
        }

    }
}
