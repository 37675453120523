@import "../../../../sass/imports";

.feed-item-container {
    vertical-align: top;
    position: relative;
    overflow: visible;

    @include min-screen(500px) {
        .opacity-container { opacity: 0.15; }

        &.feed-item-adjacent {
            .opacity-container { opacity: 0.5 !important; }
        }
    }

    &[data-meal-type="leftover"] {
        .feed-item-image-container {
            background-image: $leftover-card-gradient;
        }
    }

    .opacity-container {
        > span {
            z-index: 3;
        }
    }

    .hero-tag {
        position: absolute;
        top: 12px;
        right: 4px;
        z-index: 100;
        height: 32px;
        opacity: 0;
    }

    .feather-loader {
        animation-duration: 1s;
        animation-iteration-count: 120;
        animation-name: spinning;
        animation-timing-function: linear;
        display: inline-block;
    }

    &.feed-item-active {
        .opacity-container {
            opacity: 1 !important;

            > span {
                position: static;
            }
        }

        .feed-item-image-container {
            top: 0;
            @include border-radius(4px);
        }

        .feed-item-image {
            width: 256px;
            height: 170px;
            object-fit: contain;
        }

        &.image-overlay-logged, &.image-overlay-needs-logging, &.past-card {
            .feed-item-image-container:after {
                opacity: 1;
            }

            .restaurant-image-overlay-text {
                top: 60px;
            }

            .feed-item-info {
                background-image: linear-gradient(to bottom, #e3e6eb, #d1d5dc);
            }
        }

        .feed-item-info, .card-image-btns, .image-overlay-text, .restaurant-image-overlay-text, .add-photo-btn,
        .image-overlay-content, .variances-popup {
            opacity: 1;
        }

        .restaurant-image-overlay-text {
            top: 95px;
        }

        .favorite-btn {
            opacity: 1;
            max-height: initial;
            z-index: 10;
        }

        .card-date-indicator {
            opacity: 0;
            z-index: 0;
        }

        .current-meal-tag {
            opacity: .6;
        }

        .hero-tag {
            opacity: 1;
        }
    }

    .card-date-indicator {
        opacity: 1;
        position: absolute;
        z-index: 100;
        top: 310px;
        left: 50%;
        @include transform(translate(-50%, 0));
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));
        border: none;
        background-color: white;
        font-size: 12px;
        padding: 10px;
        border-radius: 40px;
        width: 196px;
        overflow: hidden;
        text-align: center;

        @include min-screen(47em) {
            top: 334px;
        }

        em {
            font-weight: bold;
        }

        &.past-tab, &.present-tab {
            .tab {
                position: absolute;
                top: 0; bottom: 0;
                left: 0;
                text-transform: uppercase;
                font-size: 11px;
                color: white;
                padding: 10px;
                font-weight: bold;
            }
        }

        &.present-tab {
            padding: 10px 10px 10px 74px;

            .tab {
                background-color: $mint-green3;
                width: 74px;
            }
        }


        &.past-tab {
            padding: 10px 10px 10px 53px;

            .tab {
                background-color: $text-gray;
                width: 53px;
            }
        }
    }


    .current-meal-tag {
        opacity: 0;
        position: absolute;
        top: -20px;
        left: 50%;
        @include transform(translate(-50%, 0));
        @include transition(opacity 0.25s ease-in-out);

        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: $text-gray;
        text-transform: uppercase;
    }

    .debug-info {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        background-color: white;
        border: 1px solid black;
        padding: 5px;
        font-size: 12px;
    }

    &.past-card {
        .feed-item-info {
            .meal-title {
                position: relative;
                z-index: 2;
                color: $raspberry;
                cursor: pointer;
            }
        }
    }

    .feed-item-card-back {
        transform-style: preserve-3d;
        position: absolute;
        top: 0; bottom: 0;
        right: 0; left: 0;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border: 1px solid $light-gray-border;
        @include border-radius(3px);
        transform: translateX(0%) rotateY(180deg) translateZ(-10px);
        transform-origin: center center;
        background-color: $super-light-gray;

        text-align: center;
        color: $text-gray;
        padding: 20px 10px;

        h1 {
            padding: 0 0 20px 0;
            font-size: 20px;
        }

        h2 {
            padding: 0 0 10px 0;
            font-size: 16px;
        }

        p {
            font-size: 12px;
            padding: 0 0 40px 0;
        }
    }

    .feed-item-card {
        // min-height: 300px;
        width: calc(100vw - 80px);
        max-width: 295px;
        border: 1px solid blue;
        margin: 20px 0 10px 0;
        vertical-align: top;
        padding: 0px;
        border: 1px solid transparent;
        z-index: 1;
        position: relative;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        will-change: transform;

        @include transition(transform 0.08s ease-in);

        .container-prompt-overlay {
            height: 90%;
            margin-top: -5px;

            > i {
                margin-left: 10px;
            }
        }
    }

    .feed-item-image-container {
        // width: calc(100% - 20px);
        // height: 296px;

        position: absolute;
        top: 30px;
        left: 50%;
        z-index: 3;
        @include transform(translateX(-50%));

        will-change: transform, top, padding-bottom, width, border-radius, box-shadow;
        background-image: $default-card-gradient;
        overflow: hidden;
        @include border-radius(4px);
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));

        @include min-screen(47em) {
            top: 58px;
        }

        &:after {
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            content: ' ';
            display: none;
            // @include transition(all .25s ease-in-out);
        }
    }

    .feed-item-image {
        cursor: pointer;

        width: 273px;
        height: 296px;
        object-fit: contain;

        &[data-has-thumb="true"] {
            background-size: cover;
            background-position: center;
            @include filter(blur(10px));

            &.img-loaded {
                @include filter(none);
            }
        }
    }

    &[data-meal-type="food"] {
        .feed-item-image-container {
            background-image: $food-card-gradient;
        }
    }

    &.image-overlay-image-btns {
        .feed-item-image-container:after {
            background-image: linear-gradient(0deg,rgba(42,45,48,.65) 0%,hsla(0,11%,98%,0) 33%);
            display: block;
            opacity: 0;
        }

    }

    &.image-overlay-needs-logging {
        .feed-item-image-container:after {
            background-image: linear-gradient(118.47deg, rgba(85, 97, 115, 0.9) 2.4%, rgba(171, 180, 193, 0.9) 100.82%);
            display: block;
            opacity: 0;
        }

        .ellipsis-menu-btn {
            padding: 7px;
        }
    }

    &.image-overlay-logged {
        .feed-item-image-container:after {
            background-color: rgba(46, 57, 77, 0.63);
            display: block;
            opacity: 0;
            top: auto;
            height: 78px;
        }
    }

    .favorite-btn {
        position: absolute;
        z-index: 4; // on top of the feed-item-image
        top: 15px;
        right: 30px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        border: none;
        background: none;
        opacity: 0;
        max-height: 0;
        @include transition(opacity 0.25s ease-in-out);

        &[data-boarded="true"] {
            color: $raspberry;
        }
    }

    .card-image-btns {
        position: absolute;
        top: 145px;
        max-width: calc(100% - 30px);
        text-align: center;
        left: 50%;
        z-index: 10;
        opacity: 0;
        @include transform(translate(-50%, 0));
        white-space: nowrap;

        button {
            width: 114px;
            @include border-radius(40px);
            @include transition(background-color 0.25s ease-in-out);
            border: none;
            color: white;
            font-size: 10px;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: 1px;
            text-align: center;
            text-transform: uppercase;
            padding: 13px 0;
            margin: 6px;
        }

        .order-details-image-btn, .start-cooking-image-btn {
            background-color: #d9dde1;
            color: $text-navy;

            &:focus {
                background-color: darken(#d9dde1, 10%);
            }
        }

        .add-meal-image-btn {
            background-color: $orange4;

            &:focus {
                background-color: darken($orange4, 10%);
            }
        }

        .log-meal-image-btn {
            background-color: $mint-green3;

            &:focus {
                background-color: darken($mint-green3, 10%);
            }

            &:hover {
                background-color: darken($mint-green3, 10%);
            }
        }
    }

    &.one-card-image-btn {
        .card-image-btns {
            button {
                width: 150px;
            }
        }
    }

    &.two-card-image-btns {

    }

    .control-btns {
        padding: 20px 0;
        text-align: center;
        position: relative;
        z-index: 2;

        &[data-has-grocery-btn="true"] {
            padding: 0 0 40px 0;
        }
    }

    .undo-btn, .log-food-btn, .no-change-meal-btn, .edit-log-btn, .add-meal-btn, .undo-delete-btn {
        @include border-radius(40px);

        border: none;

        color: white;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 20px;
        margin: 10px 5px 0px 0px;
        min-width: 80px;
        letter-spacing: 0.9px;
        line-height: 1.17;
    }

    .log-food-btn, .add-meal-btn {
        background-color: $raspberry;

        &:focus {
            background-color: darken($raspberry, 10%);
        }

        &.green-btn {
            background-color: #2cd296;
        }
    }

    .no-change-meal-btn, .edit-log-btn, .undo-delete-btn {
        background-color: $bluey-gray;

        &:focus {
            background-color: darken($bluey-gray, 10%);
        }
    }

    .undo-btn {
        padding: 9px 3px;
        background-color: white;
        color: $bluey-gray;
        border: 1px solid $bluey-gray;

        &:focus {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    .photo-upload-container {
        position: absolute;
        right: 8px;
        top: 150px;
        z-index: 10;
    }

    .add-photo-btn {
        position: relative;
        display: inline-block;
        vertical-align: top;
        opacity: 0;

        > button {
            background-color: $raspberry;
            border: none;
            color: white;
            font-size: 19px;
            width: 35px;
            height: 35px;
            @include border-radius(35px);

            i {
                display: inline-block;
            }
        }

        input {
            position: absolute;
            top: 0; bottom: 0;
            right: 0; left: 0;
            opacity: 0;
            width: 100%;
        }
    }

    .feed-item-preload-image {
        opacity: 0;
        position: absolute;
        top: 0; left: 0;
        width: 1px; height: 1px;
        z-index: 4;
    }

    .meal-slot-name {
        color: darken(#2eabe4, 10%);
        text-align: center;
        font-size: 20px;
        font-weight: normal;
        margin: 0 0 4px 0;

        em {
            color: $text-navy;
            font-weight: 800;
        }
    }

    .image-overlay-text, .restaurant-image-overlay-text, .image-overlay-content {
        position: absolute;
        top: 95px;
        left: 50%;
        z-index: 10;
        @include transform(translate(-50%, -50%));
        width: calc(100% - 45px);

        opacity: 0;
        color: white;

        &[data-log-info="true"] {
            top: 65px;
        }

        &[data-loading="true"] {
            background-color: rgba(71, 86, 114);
            height: 170px;
            top: 86px;
            width: 256px;
            opacity: 0.85;
        }
    }

    .logging-summary-overlay {
        top: 133px;
    }

    .image-overlay-content {
        text-align: center;

        h1 {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 800;
            margin: 0;
        }

        span {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            display: block;
            max-width: 75%;
            margin: auto;
        }
    }

    .image-overlay-text {
        color: white;
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        line-height: 21.6px;
        letter-spacing: 2px;

        footer {
            text-align: center;
        }

        .icon-spinner2 {
            font-size: 25px;
            display: inline-block;
        }

        .feather-loader {
            font-size: 40px;
            position: relative;
            top: 60px;
        }

        span {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            display: block;
            max-width: 75%;
            margin: auto;
        }
    }

    .image-overlay-content {
        width: calc(100% - 37px); // all the way to the edge of the image
    }

/*
Removed in favor of plain white text
.text-over-image {
    text-shadow: 0px 0px 5px black;
}
*/

    .plus-btn {
        background: none;
        border: none;
        font-size: 40px;
        padding: 110px;
        color: $text-navy;
    }

    .image-overlay-empty {
        width: calc(100% - 37px); // all the way to the edge of the image
        opacity: 1 !important;
        text-align: center;
        position: absolute;
        top: 200px;
        left: 50%;
        z-index: 10;
        @include transform(translate(-50%, -50%));
    }

    .restaurant-image-overlay-text {
        font-weight: normal;
        text-align: center;
        color: white;
        opacity: 0.8;

        top: 200px;
        // @include transition(top 0.25s ease-in-out);

        h1 {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
        }

        p {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .icon-logo2 {
            font-size: 60px;
            opacity: .2;

            .path1:before {
                color: $text-navy;
            }
        }
    }

    .feed-item-info {
        opacity: 0;
        position: relative;
        width: 100%;
        height: 380px;
        z-index: 2;
        padding: 55% 0px 0px 0px;
        margin: 30px 0 50px 0;
        background-color: white;
        @include transition(opacity 0.25s ease-in-out);
        @include border-radius(4px);
        @include box-shadow(0, 10px, 20px, 0, rgba(0, 0, 0, 0.1));

        display: flex;
        flex-direction: column;


        h1 {
            color: $text-navy;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
        }

        h2 {
            text-align: center;
            color: $text-navy;
            font-size: 14px;
            margin: 0px 10px 11px 10px;
            line-height: 1.29;
            font-weight: bold;
            height: 2.5em;
            overflow: hidden;

            &.no-meal-scheduled {
                // color: $bluey-gray !important;
                margin: 0;
            }

            > span {
                &:before {
                    content: ' + ';
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .mods {
            color: $raspberry;
            display: inline-block;
            margin: 0 0 0 4px;
        }

        h3 {
            text-align: center;
            text-transform: uppercase;
            font-size: 12px;
            color: $text-navy;
            margin: 0 0 5px 0;
        }

        > footer {
            text-align: center;
            padding: 5px 0 50px 0;
            position: relative;
        }

        &[data-has-image-btns="true"] {
            padding: 57% 0 0 0;
        }

        &[data-loading="true"] {

            @include keyframes(skeleton-load) {
                0% {
                    left: -200%;
                }
                100% {
                    left: 200%;
                }
            }

           .meal-title-frame, .swap-btn-frame, .clear-btn-frame, .more-btn-frame {
                color: transparent;
                position: relative;
                min-height: 14px;
                overflow: hidden;
                border-radius: 17px;

                &:before {
                    display: block;
                    width: 200%;
                    height: 100%;
                    content: ' ';
                    background: linear-gradient(90deg, transparent 0%, #EAEAEA 50%, transparent 100%);
                    @include animation-name(skeleton-load);
                    @include animation-duration(2s);
                    @include animation-timing-function(linear);
                    @include animation-iteration-count(infinite);

                    position: absolute;
                    top: 50%; @include transform(translateY(-50%));
                    left: -100%;
                }

                &:after {
                    width: 80%;
                }
            }

            .meal-title-frame-container {

                &[data-grocery="true"]{
                    margin-bottom: 20px;
                }

                .meal-title-frame {
                    max-width: 193px;
                    height: 14px;
                    margin: 5px 10px 0px 40px;

                    &:first-child {
                        max-width: 221px;
                        margin: 10px 10px 0px 30px;
                    }
                }
            }

            .swap-btn-frame {
                max-width: 100px;
                height: 30px;
                left: 20px;
            }

            .clear-btn-frame {
                max-width: 100px;
                height: 30px;
                left: 130px;
                bottom: 30px;
            }

            .more-btn-frame {
                max-width: 30px;
                height: 30px;
                left: 240px;
                bottom: 60px;
            }

        }
    }

    .feed-item-clickbox {
        position: absolute;
        z-index: 1;
        top: 0; bottom: 0;
        left: 0; right: 0;
        cursor: pointer;
    }

    .drawer-menu-btn, .done-btn {
        text-align: center;
        border: none;
        background-color: rgba(66, 85, 105, 0.2);
        padding: 10px 20px;
        @include border-radius(10em);
        margin: 5px 5px 5px 10px;
        color: $text-gray;
        font-size: 10px;
        letter-spacing: 0.9px;
        font-weight: bold;
        text-transform: uppercase;
        @include transition(background-color 0.25s ease-in-out);

        i {
            vertical-align: middle;
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            &.feather-refresh-ccw {
                font-size: 16px;
                position: relative;
                left: 0.5px;
                bottom: 1px;
                margin-right: 0px;
            }
        }

        .icon-ellipsis2 {
            margin-right: 0 !important;
        }

        &:disabled {
            opacity: 0.25;
        }

        &:focus {
            background-color: $bluey-gray;
        }

        &:hover {
            background-color: darken(rgba(66, 85, 105, 0.2), 80%);
        }
    }

    .swap-menu-btn {
        background-color: $raspberry;
        color: white;

        &:focus {
            background-color: darken($raspberry, 10%);
        }

        &:hover {
            background-color: darken($raspberry, 10%);
        }
    }

    .ellipsis-menu-btn {
        padding: 10px;
        background-color: rgba(66, 85, 105, 0.2);
    }

    .refresh-btn {
        padding: 9px;
        background-color: rgba(66, 85, 105, 0.2);
        right: 260px;
        z-index: 3;
    }

    .feed-item-thumped {
        .feed-item-card {
            @include transform(scale(0.95));
        }
    }

    .variances-popup {
        position: absolute;
        right: 10px;
        top: 200px;
        z-index: 20;
        opacity: 0;
    }

    .provider-logo {
        display: block;
        height: 21px;
        margin: 0 auto 0 auto;
    }

    .provider-logo-spacer {
        height: 21px;
    }
}


