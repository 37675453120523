@import "../../../sass/imports";

.landing-page {
    color: $text-navy;
    background-color: white;
    margin: 0;

    @supports (padding-top: constant(safe-area-inset-top)) {
        margin-top: constant(safe-area-inset-top);
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        margin-top: env(safe-area-inset-top);
    }

    > h1 {
        text-align: center;
        font-size: 36px;
        margin: 1em auto 0em auto;
        max-width: 680px;
        padding: 0 20px;

        @include min-screen(47em) {
            text-align: left;
        }
    }

}

.landing-modules {
    max-width: 1200px;
    margin: auto;
}

.landing-module {

}
