
@import "../../../sass/imports";

.macros-pie-chart-v2 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    @include max-screen(47em) {
        gap: 10px;
    }

    .macros-micros {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: wrap;
    }

    .chart {
        width: 98px;
        height: 98px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        flex-shrink: 0;
    }

    .macros-legend, .micros-legend {
        display: flex;
        gap: 10px;
        flex-direction: column;
        font-size: 16px;


        .color-key {
            width: 20px;
            height: 20px;
            border: 1px solid transparent;
            border-radius: 6px;
        }

        .cho {
            background-color: #e97a99;
        }

        .pro {
            background-color: $mint-green3; // #2cd296
        }

        .fat {
            background-color: $bluey-gray; // #a8acbe
        }

        li {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            .name {
                width: 55px;
                word-wrap: normal;
            }

            .nutr {
                width: 40px;
                word-wrap: normal;
                text-align: right;
            }
        }

        .name {
            color: #ffffff;

            > span {
                font-weight: bold;
            }
        }
    }

    .micros-legend {
        flex: 1;

        li {
            .nutr {
                flex: 1;
                width: 80px;
                max-width: 95px;
            }
        }
    }
}
