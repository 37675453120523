
@import "../../../../sass/imports";
@import "../../Groceries/DateRange.scss";

.choose-date-range-modal {
    text-align: center;

    .el-labeled-input {

        label {
            text-align: center;
        }
    }
}
