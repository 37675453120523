
@import "./../../../../sass/imports";

.groceries-dropdown {
    .dropdown-dropdown {
        @include transform(translate(-80%, -20px));

        .close-btn {
            display: none;
        }
    }

    .triangle, .triangle2 {
        left: 80%;
    }

    ul {
        padding: 10px 20px;
        max-height: 320px;
        overflow-y: auto;

        li {
            padding: 10px 0;
            font-size: 12px;
            color: $text-gray;

            img {
                width: 50px;
                height: 50px;
                margin: 0 10px 0 0;
                @include box-shadow(0, 8px, 8px, 0, rgba(0, 0, 0, 0.2));
                vertical-align: middle;
                @include border-radius(3px);
            }

            p {
                display: inline-block;
                max-width: calc(100% - 60px);
                vertical-align: middle;
                font-size: 14px;
                color: $text-navy;
                font-weight: bold;
            }
        }
    }

    .recent {
        padding: 10px 20px;
        border-bottom: 1px solid $light-gray-border;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $text-navy;
    }

    .groceries-dropdown-footer {
        padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: $raspberry;
        @include transition(all 0.1s ease-in-out);

        &:focus {
            background-color: $raspberry;
            color: white;
        }
    }
}
