
@import "../../sass/imports";


.dashboard-page {
    min-height: calc(100vh - 20px);
    background-color: $dark-slate-gray;

    @supports (min-height: calc(100vh - 20px - constant(safe-area-inset-top))) {
        min-height: calc(100vh - 20px - constant(safe-area-inset-top))
    }
    @supports (min-height: calc(100vh - 20px - env(safe-area-inset-top))) {
        min-height: calc(100vh - 20px - env(safe-area-inset-top))
    }


    .feed {
        width: 100%;
        margin: 0;
    }

    > section.top {
        background-color: #fafafa;
        padding: 0;

        @include min-screen(47em) {
            padding: 1em 0;
        }
    }

    > section.bottom {
        max-width: 1000px;
        margin: auto;

        > div {
            display: block;
        }

        @include min-screen(47em) {
            > div {
                display: inline-block;
                vertical-align: top;

                &:first-child {
                    border-right: solid 0.5px #edeeef;
                    width: 55%;
                    padding-bottom: 5em;
                }

                &:last-child {
                    padding: 1em 2em;
                    width: 45%;
                }
            }
        }
    }

    footer.footer {
        display: none;
    }

    @include min-screen(47em) {
        footer.footer {
            display: block;
        }
    }

    .footer-hill {
        background-image: linear-gradient(180deg, #324253, #000000 45%, #000);
        width: 100%;
        min-height: calc(100vh - 20px - env(safe-area-inset-top));
        overflow: hidden;
        position: fixed;
    }
}
