
@import "../../../sass/imports";

.customizer-page {
    background-color: #fafafa;
    padding: 1px;

    .meal-plan-customizer {
        margin: auto;
        > h1 {
            max-width: 1000px;
            margin: 20px auto;

            @include min-screen(1000px) {
                margin: 20px 10px;
            }
        }
    }

    .eatlove-logo-header {
        display: block;
        margin: 20px auto;
        max-width: 150px;
    }

    .customizer-scroll-container {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        padding-top: 65px;
    }

    .primary-actions {
        position: fixed;

        @include min-screen(47em) {
            z-index: 2;
            top: 80px;
            right: 0;

            &[data-pro="false"] {
                top: 110px;
                right: 50px;
            }
        }
    }


.patient-recommend-plans {
    position: relative;
    z-index: 1;
    padding: 40px 0 50px 0;
    min-height: 1000px;
    margin-top: 100px;

    @include min-screen(47em) {
        background: none;
        padding: 0;
        overflow-x: auto;
        transform: translateX(30%);

        &[data-loading="true"] {
            transform: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .recommend-header {
            @include background-image(linear-gradient(138deg, #da67ad, #b7498c));
        }
    }

    .loading {
        p {
            display: block;
            color: $text-gray;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            margin: 40px 0;
        }

        i {
            color: $raspberry;
            display: block;
            margin: auto;
            font-size: 40px;
            text-align: center;
        }
    }

    .header-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include min-screen(1230px) {
            flex-direction: column;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            padding-bottom: 50px;
        }

        > button {
            text-transform: uppercase;
            color: white;
            padding: 7px 9px;
            border: 1px solid white;
            @include border-radius(50px);
            background: none;
            font-size: 11px;
            white-space: nowrap;
            margin: 5px;

            @include max-screen(47em) {
                padding: 4px;
            }
        }
    }

    .plan-title {
        font-size: 16px;
        font-weight: bold;
        color: $text-gray;
        cursor: pointer;

        .regenerate-btn {
            float: right;
        }
    }

    .regenerate-btn {
        color: white;
        background-color: $raspberry;
        border: none;
        font-size: 22px;
        width: 42px; height: 42px;
        @include border-radius(50px);
    }


    .plan-size {
        li {
            border-bottom: 1px solid #d2d2d2;;
            padding: 8px 0;
            color: $text-gray;
            font-size: 18px;
            line-height: 1.43;
            width: 200px;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .edit-plan-btn, .recommend-plan-btn {
        border: none;
        text-transform: uppercase;
        color: white;
        font-size: 10px;
        padding: 10px 14px;
        @include border-radius(40px);
    }

    .edit-plan-btn {
        background-color: transparent;
        border: 1px solid $raspberry;
        color: $raspberry;
        margin: 0 16px 0 0;
    }

    .recommend-plan-btn {
        border: 1px solid $mint-green3;
        background-color: $mint-green3;
    }

    .new-plan-mode {
        position: relative;
        margin: 0 25px;

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
        }

        @include max-screen(1230px) {

            .is-personalized {
                color: $text-gray;
                font-size: 14px;
                line-height: 1.29;
                position: absolute;
                top: 20px;
                width: 100%;
                left: 0;
                padding: 0 20px;
                text-align: center;
            }

            .plan-title {
                position: absolute;
                top: 73px;
                left: 0;
                width: calc(100% - 44px);
                margin: 0 22px;
            }

            .plan-size {
                position: absolute;
                top: 240px;
                left: 225px;
                width: calc(100% - 190px);
            }

            .plan-card {
                position: absolute;
                top: 200px;
                width: 40%;
                max-width: 180px;
                left: 22px;
            }

            .plan-action-btns {
                position: absolute;
                top: 330px;
                left: 0px;
                width: 100%;
                text-align: center;
            }
        }

        @include min-screen(1230px) {

            .is-personalized {
                display: none;
            }

            .plan-title {
                position: absolute;
                top: 50px;
                left: 315px;
                margin-right: 20px;
                min-width: 225px
            }

            .plan-card {
                position: absolute;
                top: 50px;
                left: 50px;
                width: 240px;
            }

            .plan-size {
                position: absolute;
                left: 315px;
                top: 150px;
            }

            .plan-action-btns {
                position: absolute;
                width: 100%;
                top: 244px;
                text-align: center;
            }
        }

        @include max-screen(400px) {

            .plan-size {
                position: absolute;
                top: 240px;
                left: 170px;
                width: calc(100% - 190px);
            }

            .plan-card {
                position: absolute;
                top: 230px;
                width: 40%;
                max-width: 180px;
                left: 22px;
            }
        }


        .plan-card {
            display: inline-block;
            position: relative;

            .plan-card-image {
                bottom: 100%;
            }

            .plan-card-image-blurry {
                @include filter(blur(0px));
            }

            .plan-card-text {
                background-color: transparent; // rgba(0, 0, 0, 0.4);
                top: 0;
                bottom: 0;
                height: auto;

                > div {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    height: auto;
                }
            }
        }

    }

    .module-content {
        position: relative;

        > footer {
            white-space: nowrap;
        }

        @include max-screen(1230px) {
            min-height: 390px;

            // margin: 0 25px;
            background-color: white;
            @include border-radius(8px);
        }

        @include max-screen(47em) {
            @include box-shadow(0, 1px, 4px, 0, rgba(0, 0, 0, 0.36));
            margin: 0 25px;
        }

        @include min-screen(47em) {
            box-shadow: none;
        }

        @include min-screen(1230px) {
            margin-left: 290px;
            width: calc(100% - 290px);
            min-width: 360px;
            min-height: 378px;
        }
    }

    .recommend-header {
        padding: 20px;
        text-align: center;
        z-index: 1;

        h2 {
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            opacity: 1;
        }

        p {
            display: none;
            color: white;
            font-size: 14px;
            line-height: 1.43;

            @include min-screen(47em) {
                display: block;
            }

            @include min-screen(1230px) {
                margin: 20px 0 0 0;
            }
        }

        @include min-screen(1230px) {
            position: absolute;
            top: 0; bottom: 0;
            left: 0;
            width: 290px;
            padding: 27px 40px;

            text-align: left;
        }
    }

    .loading-plans {
        li {
            padding: 5px 0 5px 0 !important;
            margin: 0 !important;
            border-bottom: none !important;
        }
    }


    .view-or-edit {
        position: absolute;
        left: 50%;
        top: 75%;
        @include transform(translate(-52%, -50%));
        @include flat-button(darken($text-gray, 18%));
        @include border-radius(0);
        padding: 8px;
        font-size: 10px;
        border-width: 1px;
        width: 85%;
        max-width: 350px;
    }

    .incomplete-plan {
        a {
            text-decoration: underline;

            @include min-screen(47em) {
                color: white;
                font-weight: bold
            }
        }

        &:before {
            @include icon-font;
            content: $icon-warning1;
            color: $orange;
            font-size: 20px;
            padding-right: 10px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .no-plans-found {
        padding: 30px;
        width: 100%;

        i {
            font-size: 3em;
            color: $text-gray;
            padding: .25em 1em;
        }

        ul {
            text-align: left;
            list-style: disc;
            display: block;
            margin: 20px auto;
            padding-left: 40px;
        }

        li {
            color: $text-gray;
            font-size: 12px;
        }

        footer {
            text-align: center;

            button {
                background-color: $raspberry;
                color: white;
                padding: 9px 12px;
                font-size: 10px;
                text-transform: uppercase;
            }

            .try-again-btn {
                background-color: white;
                color: $text-gray;
                border: 1px solid $bluey-gray;
                margin: 0 10px 0 0;
            }
        }

        h3 {
            text-align: left;
            font-size: 16px;
            color: $text-gray;
            font-weight: bold;

            &:before {
                @include icon-font;
                content: $icon-warning1;
                color: $orange;
                font-size: 20px;
                padding-right: 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        p {
            color: $text-gray;
            font-size: 12px;
            margin: 1em auto;
            text-align: left;
            padding-left: 40px;

            a {
                text-decoration: underline;
            }

            em {
                font-weight: bold;
            }
        }
    }


}


    
}
