
@import "../../../../sass/imports";

.subscription-required-modal {
    h3 {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: $text-gray;
    }

    p {
        color: $text-gray;
        font-size: 14px;
        text-align: center;
    }
}
